import {
    Menu, Avatar, Divider,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoLogOutOutline } from 'react-icons/io5';
import cs from 'classnames';
import styles from './styles.module.scss';

import IMAGES from '../../const/images';
import { startLogout } from '../../actions/auth';

interface SideMenuProps {
  isCollapsed: boolean;
}

const SideMenu = ({ isCollapsed }: SideMenuProps) => {
    const { t: tp } = useTranslation('shared', { keyPrefix: 'PAGES' });

    const dispatch = useDispatch<any>();

    const pages = [
        {
            index: 0,
            title: tp('HOME'),
            icon: <Avatar shape="square" size="small" src={IMAGES.icons.home} />,
            href: '/',
        },
        {
            index: 1,
            title: tp('BOOKINGS'),
            icon: <Avatar shape="square" size="small" src={IMAGES.icons.package} />,
            href: '#',
            children: [
                {
                    index: 11,
                    title: tp('ACTIVE_BOOKINGS'),
                    icon: '',
                    href: '/bookings/actives',
                },
                {
                    index: 12,
                    title: tp('HISTORY_BOOKINGS'),
                    icon: '',
                    href: '/bookings/history',
                },
            ],
        },
        {
            index: 3,
            title: tp('DRIVERS'),
            icon: <Avatar shape="square" size="small" src={IMAGES.icons.driver} />,
            href: '/drivers',
        },
        {
            index: 4,
            title: tp('SETTINGS'),
            icon: <Avatar shape="square" size="small" src={IMAGES.icons.settings} />,
            href: '/settings',
        },
    ];

    return (
        <div className={styles.sideMenu}>
            <Menu mode="inline">
                <div className={cs(styles.infoDelivery, { [styles.collapsed]: isCollapsed })}>
                    <Avatar
                        src={IMAGES.logo.provisional}
                        size={isCollapsed ? 48 : 84}
                        className={styles.logo}
                        shape="square"
                    />
                </div>
                {
                    pages.map((page) => {
                        if (page.children) {
                            return (
                                <Menu.SubMenu
                                    key={page.index}
                                    icon={page.icon}
                                    title={page.title}
                                >
                                    { page.children.map((child) => (
                                        <Menu.Item
                                            className={cs(styles.item, { [styles.collapsed]: isCollapsed })}
                                            key={child.index}
                                            icon={child.icon}
                                        >
                                            <Link to={child.href}>
                                                <span className="text paragraph--3 medium">
                                                    {child.title}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                    )) }
                                </Menu.SubMenu>
                            );
                        }
                        return (
                            <Menu.Item
                                className={cs(styles.item, { [styles.collapsed]: isCollapsed })}
                                key={page.index}
                                icon={page.icon}
                                title={page.title}
                            >
                                <Link to={page.href}>
                                    <span className="text paragraph--3 medium">
                                        {page.title}
                                    </span>
                                </Link>
                            </Menu.Item>
                        );
                    })
                }

                <Divider className={styles.divider} />

                <Menu.Item
                    className={cs(styles.item, { [styles.collapsed]: isCollapsed })}
                    key="logout"
                    icon={<IoLogOutOutline size={20} color="#6C8EEF" />}
                    title={tp('LOGOUT')}
                    onClick={() => dispatch(startLogout())}
                >
                    <span className="text paragraph--3 medium">
                        { tp('LOGOUT') }
                    </span>
                </Menu.Item>
            </Menu>
        </div>
    );
};

export default SideMenu;
