import { ISmallFleetIcon } from '@/types/icon';

export const SmallFleet = ({ size = 16, color = '#000', fleetBackground }: ISmallFleetIcon) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        viewBox='0 0 87 87'
        fill='none'
    >
        <ellipse cx='43.1045' cy='43.5' rx='43.1045' ry='43.5' fill={color} />
        <ellipse cx='43.1047' cy='43.5005' rx='27.9153' ry='28.1714' fill={fleetBackground ? fleetBackground : "white"} />
        <path
            d='M55.7026 35.0258C55.7026 33.1616 54.1773 31.6362 52.313 31.6362H48.9234C47.9912 
            31.6362 47.2286 32.3989 47.2286 33.331C47.2286 34.2632 47.9912 35.0258 48.9234 35.0258H52.313V39.5171L46.4151 
            46.8895H40.4493V40.1103C40.4493 39.1781 39.6867 38.4155 38.7545 38.4155H33.6701C29.9246 38.4155 26.8909 41.4492 
            26.8909 45.1947V48.5843C26.8909 49.5164 27.6536 50.2791 28.5857 50.2791H30.2805C30.2805 53.0925 32.5515 55.3635 
            35.3649 55.3635C38.1783 55.3635 40.4493 53.0925 40.4493 50.2791H46.4151C47.4489 50.2791 48.4149 49.8045 49.059 
            49.008L54.9569 41.6356C55.4484 41.0255 55.7026 40.2797 55.7026 39.5171V35.0258ZM35.3649 51.9739C34.4328 51.9739 
            33.6701 51.2112 33.6701 50.2791H37.0597C37.0597 51.2112 36.2971 51.9739 35.3649 51.9739Z'
            fill={color}
        />
        <path
            d='M33.6701 33.3311H38.7545C39.6866 33.3311 40.4493 34.0937 40.4493 35.0259C40.4493 35.958 
                39.6866 36.7207 38.7545 36.7207H33.6701C32.7379 36.7207 31.9753 35.958 31.9753 35.0259C31.9753 
                34.0937 32.7379 33.3311 33.6701 33.3311ZM55.7026 45.1947C52.8892 45.1947 50.6181 47.4657 50.6181 
                50.2791C50.6181 53.0925 52.8892 55.3635 55.7026 55.3635C58.5159 55.3635 60.787 53.0925 60.787 
                50.2791C60.787 47.4657 58.5159 45.1947 55.7026 45.1947ZM55.7026 51.9739C54.7704 51.9739 54.0077 
                51.2112 54.0077 50.2791C54.0077 49.347 54.7704 48.5843 55.7026 48.5843C56.6347 48.5843 57.3974 
                49.347 57.3974 50.2791C57.3974 51.2112 56.6347 51.9739 55.7026 51.9739Z'
            fill={color}
        />
    </svg>
)