import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const LoadingPage = () => {
    const { t } = useTranslation('shared');
    return (
        <div className={styles.loader}>
            <object width={64} height={64} type="image/svg+xml" data="/images/loader.svg">
                <img src="/images/loader.svg" alt="loader" />
            </object>
            <span className="center medium" style={{ marginTop: 6 }}>
                { t('LOADING') }
            </span>
        </div>
    );
};

export default LoadingPage;
