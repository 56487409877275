import { Space } from 'antd';
import Account from './Account';
import styles from '../styles.module.scss';

const General = () => (
    <div className={styles.containerSection}>
        <Space direction="vertical" size={16} className="fullWidth">
            <span className="title--header-2 bold cl--secondary-purple">
                Información general
            </span>
            <Account />
        </Space>
    </div>
);

export default General;
