import {
    Row, Col, Space,
} from 'antd';
import { BiPackage } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

import HeaderSection from '../components/HeaderSection';
import { Bookings } from '../components/Bookings';
import Drivers from '../components/Drivers';

const Home = () => {
    const { t: th } = useTranslation('home', { keyPrefix: 'HOME' });

    return (
        <div className="fullHeight fullWidth home flex-row center-row-start">
            <Row
                align="top"
                justify="space-around"
                className="fullWidth fullHeight noMargin"
                gutter={[24, 48]}
            >
                <Col xs={24} lg={16} className="fullHeight" style={{ paddingLeft: 0 }}>
                    <div className="contentLayouts fullWidth fullHeight bg--primary-white">
                        <Space direction="vertical" size={32} className="fullWidth">
                            <HeaderSection
                                title={th('ACTIVE_BOOKINGS')}
                                icon={<BiPackage size={40} className="iconHeader" />}
                            />
                            <Bookings />
                        </Space>
                    </div>
                </Col>
                <Col xs={0} lg={8} className="fullHeight" style={{ paddingRight: 0 }}>
                    <div className="contentLayouts fullWidth fullHeight bg--primary-white">
                        <Drivers />
                    </div>
                </Col>

            </Row>
        </div>
    );
};

export default Home;
