import { Space, Button } from 'antd';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface OptionsProps {
  active: number
}

const Options = ({ active }: OptionsProps) => {
    const { t } = useTranslation('login');

    const options = [
        {
            key: 'login',
            link: '/login',
            text: t('GO_LOGIN'),
        },
        {
            key: 'signup',
            link: '/signup',
            text: t('GO_SIGNUP'),
        },
    ];

    return (
        <div className="options flex-row center-row-center full-width">
            <Space direction="horizontal" size={24}>
                {
                    options.map((option: any, index: number) => (
                        <Link to={option.link} key={option.key}>
                            <Button
                                type={active === index ? 'primary' : 'ghost'}
                                className="stateButton"
                            >
                                {option.text}
                            </Button>
                        </Link>
                    ))
                }
            </Space>
        </div>
    );
};

export default Options;
