/* eslint-disable import/no-cycle */
import types from '../types';
import { Workspace } from '../types/workspace';
import { Action } from './root';

export type WorkspaceState = {
  workspaces: Workspace[];
  active: Workspace;
  loading: boolean;
};

const initialState = {
    loading: false,
    active: {} as Workspace,
    workspaces: [] as Workspace[],
};

export const workspaceReducer = (state = initialState, action: Action) => {
    switch (action.type) {
    case types.workspaceStartLoading:
        return {
            ...state,
            loading: true,
        };
    case types.workspaceFinishLoading:
        return {
            ...state,
            loading: true,
        };

    case types.workspaceLoad:
        return {
            ...state,
            workspaces: [...action.payload],
        };

    case types.workspaceSetActive:
        return {
            ...state,
            active: { ...action.payload },
        };

    case types.workspaceClean:
        return {};

    default:
        return state;
    }
};
