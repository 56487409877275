import { List } from 'antd';
import styles from './styles.module.scss';

interface ItemProps {
  color?: string;
  line?: boolean;
  avatar?: any;
  title: any;
  description: any;
  actions?: any;
}

const ItemPrice = ({
    color,
    line,
    avatar,
    title,
    description,
    actions,
}: ItemProps) => (
    <List.Item
        actions={actions}
        className={` ${!line ? styles.noLineDown : null} ${styles.item}`}
        style={{ background: color }}
    >
        <List.Item.Meta
            avatar={avatar}
            title={title}
        />
        <div className={styles.number}>{description}</div>
    </List.Item>
);

export default ItemPrice;
