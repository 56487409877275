/* eslint-disable import/no-extraneous-dependencies */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

import { LS_USER_KEY, LS_WORKSPACE_KEY } from '../const/local-storage-keys';

import { rootReducer } from '../reducers/root';

const userStorage = localStorage.getItem(LS_USER_KEY);
const workspaceStorage = localStorage.getItem(LS_WORKSPACE_KEY);

const userData = userStorage ? JSON.parse(userStorage) : { emailExist: null };
const workspaceData = workspaceStorage ? JSON.parse(workspaceStorage) : { };

const initialData = {
    auth: {
        ...userData,
    },
    workspace: {
        ...workspaceData,
    },
};

export const store = createStore(
    rootReducer,
    initialData,
    composeWithDevTools(
        applyMiddleware(thunk),
    ),
);
