import { Space, Button, notification } from 'antd';
import { AiFillIdcard, AiOutlineCar } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { get } from 'lodash';

import DriverCard from '../DriverCard';
import Status from '../Status';
import { addDriver } from '../../api/drivers';
import { RootState } from '../../reducers/root';

interface SendInvitationProps {
  driverData: any;
  vehicleData: any;
}

const SendInvitation = ({ driverData, vehicleData }: SendInvitationProps) => {
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });
    const { t: ta } = useTranslation('shared', { keyPrefix: 'ACTIONS' });

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const { active } = useSelector((state: RootState) => state.workspace);

    const openStatus = () => setStatus(true);
    const closeStatus = () => setStatus(false);

    const sendInvitation = () => {
        setLoading(true);
        const payload = {
            ...driverData,
            ...vehicleData,
            smallFleetID: active?._id,
        };
        addDriver(payload).then(() => {
            openStatus();
        }).catch((error: any) => {
            const message = get(error, 'response.data.message');
            notification.error({
                message: td('DRIVER_NO_ADDED'),
                description: message,
            });
        }).finally(() => setLoading(false));
    };

    return (
        <div className="fullWidth fullHeight">
            <Status
                visible={status}
                onClose={closeStatus}
                type="SUCCESS"
                topic={td('DRIVER_ADDED')}
                message={td('SEND_INVITATION_SUCCESS')}
                path="/drivers"
                action={td('GO_TO_DRIVERS')}
            />
            <Space className="fullWidth" direction="vertical" size={24}>
                <Space className="fullWidth" direction="vertical" size={64}>
                    <DriverCard
                        title={td('DRIVER_INFORMATION')}
                        icon={<AiFillIdcard color="#6C8EEF" size={24} />}
                        type="DRIVER"
                        driver={{ ...driverData }}
                    />
                    <DriverCard
                        title={td('VEHICLE_INFORMATION')}
                        icon={<AiOutlineCar color="#6C8EEF" size={24} />}
                        type="VEHICLE"
                        driver={{ ...vehicleData }}
                    />
                </Space>
                <Button
                    type="primary"
                    className="lg"
                    onClick={() => sendInvitation()}
                    loading={loading}
                >
                    {ta('FINALIZE')}
                </Button>
            </Space>
        </div>
    );
};

export default SendInvitation;
