import {
    Modal, Avatar, Space, Button,
} from 'antd';
import cs from 'classnames';
import { useNavigate } from 'react-router-dom';

import { AiFillCheckCircle, AiFillWarning, AiFillCloseCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

interface StatusProps {
  onClose: any;
  visible: boolean;
  type: string;
  topic: string;
  message: string;
  path: string;
  action: string;
}

const Status = ({
    onClose, visible, type, topic, message, path, action, ...props
} : StatusProps) => {
    const navigate = useNavigate();

    const ICON_TYPE : any = {
        SUCCESS: <AiFillCheckCircle size={56} color="#6C8EEF" />,
        WARNING: <AiFillWarning size={56} color="#FFD700" />,
        ERROR: <AiFillCloseCircle size={56} color="#FF0000" />,
    };

    const modalProps = {
        centered: true,
        closable: false,
        visible,
        footer: null,
    };

    return (
        <Modal
            {...modalProps}
            {...props}
        >
            <div className={cs(styles.bodyModal)}>
                <Space direction="vertical" size={16} className="fullWidth">
                    <Avatar
                        icon={ICON_TYPE[type]}
                        size={64}
                        className={cs(styles.iconPermission, 'flex-column center-column-center')}
                        style={{ background: '#D1DDFF', marginBottom: 24, margin: '0 auto' }}
                    />
                    <Space direction="vertical" size={8}>
                        <span className="title--header-2 cl--secondary-purple center">
                            { topic }
                        </span>
                        <span className="medium paragraph--3 cl--secondary-purple center">
                            { message }
                        </span>
                    </Space>
                    <Button
                        type="primary"
                        className="lg"
                        onClick={() => navigate(path)}
                    >
                        {action}
                    </Button>
                </Space>
            </div>
        </Modal>
    );
};

export default Status;
