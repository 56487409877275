import {
    Space,
    Avatar,
    Row,
    Col,
    List,
} from 'antd';
import cs from 'classnames';
import moment from 'moment';
import { AiTwotoneCalendar, AiOutlineClockCircle } from 'react-icons/ai';
import { BsPhone } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { Booking } from '../../types/booking';
import { PaymentMethod as PaymentMethodType } from '../../types/payment';

import IMAGES from '../../const/images';
import styles from './styles.module.scss';

interface BookingInfoProps {
  bookingData: Booking;
}

interface LocationProps {
  key: number,
  name: string,
  title: string,
  icon: string,
  address: string,
  reference: string | null,
}

const Info = ({ bookingData }: BookingInfoProps) => {
    const { t: td } = useTranslation('bookings', { keyPrefix: 'DETAIL' });
    const { t: to } = useTranslation('bookings', { keyPrefix: 'ORDER_FINISHED' });
    const { t: tt } = useTranslation('bookings', { keyPrefix: 'TABLE' });

    const isDeliver = bookingData.paymentMethod === PaymentMethodType.CASH;

    const locations: LocationProps[] = [
        {
            key: 0,
            name: 'pickup',
            title: to('PICKUP_LOCATION'),
            icon: IMAGES.booking.store_location,
            address: bookingData.pickupAddress,
            reference: bookingData.pickupReferences,
        },
        {
            key: 1,
            name: 'delivery',
            title: to('DELIVERY_LOCATION'),
            icon: IMAGES.booking.customer_location,
            address: bookingData.deliveryAddress,
            reference: bookingData?.deliveryReferences,
        },
    ];

    const getFormatDate = (date: Date, type: string) => {
        if (type === 'time') {
            return moment(date).format('HH:mm');
        }
        return moment(date).format('DD/MM/YYYY');
    };

    const renderPickupDelivery = (location : LocationProps) => (
        <div className={cs(
            'flex-column center-column-center',
            styles.cardInfo,
            styles[location.name],
        )}
        >
            <List.Item.Meta
                className="fullWidth"
                avatar={(
                    <Avatar
                        src={location.icon}
                        shape="square"
                        size={24}
                    />
                )}
                title={(
                    <span className="medium left cl--primary-dark-blue-3 paragraph--3 fullWidth">
                        { location.title }
                    </span>
                )}
                description={(
                    <span className="left cl--primary-dark-blue-3 paragraph--3 fullWidth">
                        { location.address }
                    </span>
                )}
            />
        </div>
    );

    const renderFooterBar = () => (
        <Row justify="space-around" align="middle" className="noMargin" gutter={[8, 8]}>
            <Col xs={24} md={12}>
                <List.Item.Meta
                    avatar={<AiTwotoneCalendar color="6C8EEF" size={20} />}
                    title={to('DATE')}
                    description={getFormatDate(bookingData.createdAt, 'date')}
                />
            </Col>
            <Col xs={24} md={12}>
                <List.Item.Meta
                    avatar={<AiOutlineClockCircle color="6C8EEF" size={20} />}
                    title={to('TIME')}
                    description={getFormatDate(bookingData.createdAt, 'time')}
                />
            </Col>
        </Row>
    );

    const renderInfoGeneral = () => (
        <div className={styles.cardInfo}>
            <Space direction="vertical" size={16} className="fullWidth">
                <span className="bold cl--secondary-purple ">
                    {`${to('BOOKING')}: # ${bookingData.bookingNumericId}`}
                </span>
                { renderFooterBar() }
            </Space>
        </div>
    );

    const renderDriver = () => (
        <div className={styles.cardInfo}>
            <Space direction="vertical" size={16} className="fullWidth">
                <span className="bold cl--secondary-purple ">
                    {td('DRIVER')}
                </span>

                <List.Item.Meta
                    avatar={(
                        <Avatar
                            className={styles.driverImage}
                            src={IMAGES.icons.driver}
                            size={32}
                        />
                    )}
                    title={(
                        <span className="medium left cl--primary-dark-blue-3 paragraph--3 fullWidth">
                            { bookingData?.driverName || tt('NOT_ASSIGNED') }
                        </span>
                    )}
                    description={(
                        <Space direction="horizontal" size={8}>
                            <BsPhone color="#6C8EEF" style={{ verticalAlign: 'middle' }} size={16} />
                            <span className="medium left cl--primary-dark-blue-3 paragraph--3 fullWidth">
                                { bookingData?.driverMobile || tt('NOT_AVAILABLE') }
                            </span>
                        </Space>
                    )}
                />
            </Space>
        </div>
    );

    return (
        <div>
            <Space direction="vertical" size={24} className="fullWidth">
                <span className="bold left cl--primary-dark-blue-3 paragraph--2">
                    { td('GENERAL_INFORMATION') }
                </span>
                <Row justify="space-between" align="top" className="noMargin" gutter={[16, 24]}>
                    <Col xs={24} lg={12}>
                        <Space direction="vertical" size={16} className="fullWidth">
                            <List.Item.Meta
                                avatar={(
                                    <Avatar
                                        size={64}
                                        shape="square"
                                        src={isDeliver ? IMAGES.booking.collect_and_delivery : IMAGES.booking.send}
                                    />
                                )}
                                title={(
                                    <span className="medium left cl--primary-dark-blue-3 paragraph--3 fullWidth">
                                        { to('BOOKING_TYPE') }
                                    </span>
                                )}
                                description={(
                                    <span className="bold left cl--secondary-purple paragraph--2">
                                        { isDeliver
                                            ? to('COLLECT_AND_DELIVER')
                                            : to('DELIVER') }
                                    </span>
                                )}
                            />
                            {
                                locations.map((location: LocationProps) => (
                                    renderPickupDelivery(location)
                                ))
                            }
                        </Space>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Space direction="vertical" size={16} className="fullWidth">
                            { renderInfoGeneral() }
                            { renderDriver() }
                        </Space>
                    </Col>
                </Row>
            </Space>
        </div>
    );
};

export default Info;
