import {
    Space, Steps, Avatar,
} from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BiStore } from 'react-icons/bi';
import { FaRegUserCircle } from 'react-icons/fa';
import { Booking, DistanceBreakUp as DistanceBreakUpType } from '../../types/booking';
import IMAGES from '../../const/images';

import styles from './styles.module.scss';

const { Step } = Steps;

interface DistanceBreakUpProps extends Partial<Booking>{
  distanceBreakUpData: DistanceBreakUpType;
}

interface StepProps {
  title: string;
  description: string;
  icon: ReactNode;
}

const DistanceBreakUp = ({ distanceBreakUpData }: DistanceBreakUpProps) => {
    const { t: to } = useTranslation('bookings', { keyPrefix: 'ORDER_FINISHED' });

    const STEPS : StepProps[] = [
        {
            title: to('FROM_POINT'),
            description: to('DRIVER_PICKUP_FROM_STORE'),
            icon: <BiStore size={32} />,
        },
        {
            title: to('TO_POINT'),
            description: to('DRIVER_DELIVER_TO_CLIENT'),
            icon: <FaRegUserCircle size={32} />,
        },
    ];

    const renderSteps = () => (
        <Steps current={1} labelPlacement="vertical">
            {
                STEPS.map((step: StepProps, index: number) => (
                    <Step
                        key={index}
                        title={(
                            <span className="bold left cl--secondary-purple paragraph--2 fullWidth">
                                { step.title }
                            </span>
                        )}
                        description={(
                            <span className="center cl--primary-dark-blue-3 paragraph--3 fullWidth">
                                {step.description}
                            </span>
                        )}
                        icon={step.icon}
                    />
                ))
            }
        </Steps>
    );

    const renderImage = () => (
        <div className={styles.billedDistance}>
            <Avatar
                shape="square"
                size={48}
                src={IMAGES.images.delivery}
                className={styles.avatar}
            />
            <span className="medium center cl--secondary-purple paragraph--3">
                {
                    !distanceBreakUpData?.billedDistance
                        ? '0 Km'
                        : `${distanceBreakUpData?.billedDistance.toFixed(2)} km`
                }
            </span>
        </div>
    );

    return (
        <Space direction="vertical" size={24} className="fullWidth">
            <span className="bold left cl--primary-dark-blue-3 paragraph--2 fullWidth">
                { to('DISTANCE_COVERED') }
            </span>
            <div className={styles.distanceBreakUp}>
                <Space direction="vertical" size={8}>
                    {renderImage()}
                    {renderSteps()}
                </Space>
            </div>
        </Space>
    );
};

export default DistanceBreakUp;
