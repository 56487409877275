import { Progress, Space, Button } from 'antd';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { AiOutlineRight } from 'react-icons/ai';

import cs from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from '../styles/screens/NewDriver.module.scss';
import { DriverInformation, SendInvitation, VehicleInformation } from '../components/NewDriver';

const NewDriver = () => {
    const { t: ta } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });
    const { t: ts } = useTranslation('shared');

    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(33.33);
    const [driverData, setDriverData] = useState();
    const [vehicleData, setVehicleData] = useState();

    const navigate = useNavigate();

    const STEPS = [
        ta('DRIVER_INFORMATION'),
        ta('VEHICLE_INFORMATION'),
        ta('SEND_INVITATION'),
    ];

    const nextStep = () => {
        setStep((step: number) => step + 1);
        setProgress((progress: number) => progress + 33.33);
    };

    const previousStep = () => {
        setStep((step: number) => step - 1);
        setProgress((progress: number) => progress - 33.33);
    };

    const navigator = () => (
        <div className={styles.navigator}>
            {
                step > 1 && (
                    <Button
                        className="flex-row center-row-center bg--secondary-purple-1"
                        type="primary"
                        size="large"
                        icon={<HiArrowNarrowLeft size={24} className="cl--secondary-purple" />}
                        onClick={() => previousStep()}
                    />
                )
            }

            <Space direction="vertical" size={6} className="fullWidth">
                <span className="title--header-2 center cl--primary-dark-blue">
                    { `${ts('ACTIONS.ADD')} ${ts('SINGLES.DRIVER')}` }
                </span>
                <span className="paragraph--3 medium center cl--secondary-purple">
                    { ts(STEPS[step - 1]) }
                </span>
            </Space>

            <Button
                className="flex-row center-row-center bg--secondary-purple"
                type="primary"
                size="large"
                icon={<IoMdClose size={24} className="cl--primary-white" />}
                onClick={() => navigate('/drivers')}
            />
        </div>
    );

    const progressBar = () => (
        <Progress
            className={styles.progressBar}
            percent={progress}
            showInfo={false}
            strokeColor="#6C8EEF"
        />
    );

    const renderSteps = () => (
        <div className={styles.renderSteps}>
            { step > 0 && (
                <div className={
                    cs(
                        styles.steps,
                        {
                            [styles.start]: step === 1,
                            [styles.center]: step === 2,
                            [styles.end]: step === 3,
                        },
                    )
                }
                >
                    {
                        STEPS.map((stepName: string, index: number) => (
                            <div
                                key={stepName}
                                className={
                                    cs(
                                        styles.step,
                                        {
                                            [styles.active]: index < step,
                                        },
                                    )
                                }
                            >
                                {
                                    index < step - 1
                                        ? (
                                            <BsFillCheckCircleFill className={styles.complete} color="#6C8EEF" />
                                        )
                                        : (
                                            <div className={cs(styles.number, { [styles.active]: index < step })}>
                                                <span>{index + 1}</span>
                                            </div>
                                        )
                                }
                                <span>
                                    { stepName }
                                </span>
                                <AiOutlineRight
                                    size={16}
                                    className={cs(styles.icon, { [styles.active]: index < step })}
                                />
                            </div>
                        ))
                    }
                </div>
            )}
        </div>
    );

    const renderForms = () => (
        <div className={cs('flex-column start-column-center', styles.renderForms)}>
            {step === 1 && (
                <DriverInformation
                    setDriverData={setDriverData}
                    nextStep={nextStep}
                    driverData={driverData}
                />
            )}
            {step === 2 && (
                <VehicleInformation
                    setVehicleData={setVehicleData}
                    nextStep={nextStep}
                    vehicleData={vehicleData}
                />
            )}
            {step === 3 && <SendInvitation driverData={driverData} vehicleData={vehicleData} />}
        </div>
    );

    return (
        <div className="contentLayouts fullWidth fullHeight bg--primary-white">
            <Space direction="vertical" size={24} className="fullWidth">
                { navigator() }
                { progressBar() }
                { renderSteps() }
                { renderForms() }
            </Space>
        </div>
    );
};

export default NewDriver;
