import { notification } from 'antd';
import axios from 'axios';
import userAPI from '../api/user';
import types from '../types';
import {
    finishLoading, setError, setInfo, startLoading,
} from './ui';
import { cleanWorkspace } from './workspace';

export const signup = (user: any) => ({
    type: types.signup,
    payload: { ...user, mustAnswerQuestion: true },
});

export const login = (user: any) => ({
    type: types.login,
    payload: { ...user },
});

const logout = () => ({
    type: types.logout,
});

export const validateEmail = (exist: boolean) => ({
    type: types.validateEmail,
    payload: exist,
});

export const registerMarketingData = () => ({
    type: types.registerMarketingData,
});

export const startLoadUser = (token: any) => async (dispatch: any) => {
    try {
        const { data } = await userAPI.loginWithToken(token);
        dispatch(login(data));
    } catch (error) {
        console.log(error);
    }
};

export const startValidateEmail = (email: string) => async (dispatch: any) => {
    try {
        dispatch(startLoading());
        const { data: exist } = await userAPI.validateEmail({ email });
        dispatch(validateEmail(exist));
    } catch (error) {
        console.log(error);
        dispatch(validateEmail(false));
    } finally {
        dispatch(finishLoading());
    }
};

export const startLoginEmailPassword = (email: string, password: string) => async (
    dispatch: any,
) => {
    try {
        dispatch(startLoading());
        const { data: user } = await userAPI.login({ email, password });
        dispatch(login(user));
    } catch (error: any) {
        dispatch(setError(error.response.data.message));
    } finally {
        dispatch(finishLoading());
    }
};

export const createAccount = (values: any) => async (dispatch: any) => {
    try {
        dispatch(startLoading());
        const payload = {
            ...values,
            appVersion: '1.0',
            deviceType: 'WEB',
            role: 'deliveryPartner',
        };
        const { data: user } = await userAPI.signup(payload);
        dispatch(signup(user));
    } catch (error: any) {
        console.log(error);
        dispatch(setError(error.response.data.message));
    } finally {
        dispatch(finishLoading());
    }
};

export const startLogout = () => async (dispatch: any) => {
    try {
        localStorage.clear();
        delete axios.defaults.headers.common.authorization;
        dispatch(logout());
        dispatch(cleanWorkspace());
    } catch (error: any) {
        console.log(error);
    }
};

export const startForgotPassword = (payload: any) => async (dispatch: any) => {
    try {
        dispatch(startLoading());
        const { message }: any = await userAPI.forgotPassword(payload);
        dispatch(setInfo(message));
    } catch (error: any) {
        dispatch(setError(error.response.data.message));
    } finally {
        dispatch(finishLoading());
    }
};

export const startResetPassword = (payload: any) => async (dispatch: any) => {
    try {
        dispatch(startLoading());
        const { message }: any = await userAPI.updatePassword(payload);
        dispatch(startLogout());
        dispatch(setInfo(message));
        notification.success({
            message,
            duration: 2.5,
            // eslint-disable-next-line no-restricted-globals
            onClose: () => location.replace('/login'),
        });
    } catch (error: any) {
        dispatch(setError(error.response.data.message));
    } finally {
        dispatch(finishLoading());
    }
};
