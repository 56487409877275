import {
    Form, Row, Col, Alert, Space, Input, Button,
} from 'antd';
import cs from 'classnames';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createAccount } from '../actions/auth';
import { Header, Subheader, Steps } from '../components/Onboarding';
import { PhoneNumber } from '../widgets/PhoneNumber';

import styles from '../styles/screens/Onboarding.module.scss';
import IMAGES from '../const/images';

const pattern = /^[A-ZáéíóúÁÉÍÓÚñÑ ]+$/i;

const Signup = () => {
    const { t } = useTranslation('onboarding');
    const { t: ca } = useTranslation('onboarding', { keyPrefix: 'CREATE_ACCOUNT' });

    const dispatch = useDispatch<any>();

    const [form] = Form.useForm();
    const [hasToken, setHasToken] = useState(false);
    const { ui: { msgError: error, loading }, auth: user } = useSelector((state: any) => state);

    const [defaultCountryCode, setDefaultCountryCode] = useState<string>(user.countryCode || '+593');

    useEffect(() => {
        if (!user._id) return;
        form.setFieldsValue({ ...user });
        setHasToken(true);
    }, [user, form]);

    const onSubmit = async (values: any) => {
        if (hasToken) {
            delete values.email;
        } else {
            dispatch(createAccount(values));
        }
    };

    const fieldProps = {
        label: ca('PHONE'),
        keyCountryCode: 'countryCode',
        keyMobile: 'mobile',
    };

    const handleSelect = (value: string) => {
        form.setFieldValue('countryCode', value);
        setDefaultCountryCode(value);
    };

    useEffect(() => {
        const countryCode = user.countryCode || '+593';
        handleSelect(countryCode);
    }, [user]);

    const containerForm = () => (
        <div>
            <div className={styles.header}>
                <span className="title--header-2 cl--secondary-purple">
                    {t('PERSONAL_INFORMATION')}
                </span>
            </div>
            <div className={styles.containerForm}>
                <Form form={form} onFinish={onSubmit} layout="vertical" className={styles.form}>
                    <Space direction="vertical" size={24}>
                        {error && (
                            <Alert message={error} type="error" />
                        )}
                        <Row justify="start" align="top" gutter={[24, 24]} className="noMargin">
                            <Col xs={24} md={24} lg={12}>
                                <Space direction="vertical" size={24}>
                                    <Form.Item
                                        name="name"
                                        label={ca('FIRST_NAME')}
                                        className={styles.formInput}
                                        rules={[
                                            {
                                                required: true,
                                                message: ca('FIELD_REQUIRED'),
                                            },
                                            {
                                                pattern: new RegExp(pattern),
                                                message: ca('INVALID_FIELD_STRING'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={ca('FIRST_NAME')}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="lastName"
                                        label={ca('LAST_NAME')}
                                        className={styles.formInput}
                                        rules={[
                                            {
                                                required: true,
                                                message: ca('FIELD_REQUIRED'),
                                            },
                                            {
                                                pattern: new RegExp(pattern),
                                                message: ca('INVALID_FIELD_STRING'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={ca('LAST_NAME')}
                                        />
                                    </Form.Item>
                                    <PhoneNumber
                                        form={form}
                                        fieldProps={fieldProps}
                                        handleSelect={handleSelect}
                                        defaultCountryCode={defaultCountryCode}
                                        styles={styles}
                                    />
                                </Space>
                            </Col>
                            <Col xs={24} md={24} lg={12}>
                                <Space direction="vertical" size={24}>
                                    <Form.Item
                                        name="email"
                                        label={ca('EMAIL')}
                                        className={styles.formInput}
                                        rules={[
                                            {
                                                type: 'email',
                                                message: ca('INVALID_EMAIL'),
                                            },
                                            {
                                                required: true,
                                                message: ca('FIELD_REQUIRED'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={ca('EMAIL')}
                                            disabled={hasToken}
                                        />
                                    </Form.Item>
                                    {!hasToken && (
                                        <Form.Item
                                            name="password"
                                            label={ca('PASSWORD')}
                                            className={styles.formInput}
                                            rules={[{ required: true, message: ca('FIELD_REQUIRED') }]}
                                        >
                                            <Input.Password
                                                size="large"
                                                placeholder={ca('PASSWORD')}
                                                type="password"
                                            />
                                        </Form.Item>
                                    )}
                                </Space>
                            </Col>
                        </Row>
                    </Space>
                </Form>
                <Button
                    type="primary"
                    className={cs(styles.button, 'lg')}
                    onClick={() => form.submit()}
                    loading={loading}
                >
                    {ca('CONTINUE')}
                </Button>
            </div>
        </div>
    );

    return (
        <div
            style={{ backgroundImage: `url(${IMAGES.login.spirals})` }}
            className={cs(styles.onboarding, 'flex-column center-column-center')}
        >
            <Space direction="vertical" size={24}>
                <Header />
                <Subheader />
                <Steps>
                    {containerForm()}
                </Steps>
            </Space>
        </div>
    );
};

export default Signup;
