/* eslint-disable no-unused-expressions */
import types from '../types';
import { Workspace } from '../types/workspace';

import * as WorkspaceAPI from '../api/workspace';

export const startLoading = () => ({
    type: types.workspaceStartLoading,
});

export const finishLoading = () => ({
    type: types.workspaceFinishLoading,
});

export const setWorkspaces = (workspaces: any) => ({
    type: types.workspaceLoad,
    payload: [...workspaces],
});

export const changeWorkspace = (workspace: Workspace) => ({
    type: types.workspaceSetActive,
    payload: workspace,
});

export const cleanWorkspace = () => ({
    type: types.workspaceClean,
});

export const setActiveWorkspace = (workspace: Workspace) => (dispatch: any) => {
    dispatch(changeWorkspace(workspace));
};

export const startLoadWorkspaces = () => async (dispatch: any, getState: any) => {
    try {
        const { workspace } = getState();
        if (!workspace.active) {
            dispatch(startLoading());
        }
        const { body: workspaces } : any = await WorkspaceAPI.getWorkspaces();
        dispatch(setWorkspaces(workspaces));
        if (!workspace.active) {
            workspaces[0] && dispatch(setActiveWorkspace(workspaces[0]));
            dispatch(finishLoading());
        } else {
            const activeWorkspace = workspaces.find(
                (workspace: any) => workspace?._id === workspace.active?._id,
            ) || workspaces[0];

            if (activeWorkspace) {
                dispatch(setActiveWorkspace(activeWorkspace));
            } else {
                dispatch(changeWorkspace(activeWorkspace || workspace));
            }
        }
    } catch (error) {
        console.log(error);
    }
};
