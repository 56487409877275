import cs from 'classnames';

import { Avatar } from 'antd';
import { MdDeliveryDining } from 'react-icons/md';
import { FaInfo, FaCheck, FaUserAlt } from 'react-icons/fa';

import { useLocation } from 'react-router-dom';
import { ReactElement } from 'react';

import styles from '../../styles/screens/Onboarding.module.scss';

interface StepsProps {
  children: ReactElement
}

const Steps = ({ children }: StepsProps) => {
    const { pathname } = useLocation();

    const SECTIONS = [
        {
            index: 0,
            path: '/signup',
            icon: <FaUserAlt size={20} />,
        },
        {
            index: 1,
            path: '/onboarding/about-you',
            icon: <FaInfo size={20} />,
        },
        {
            index: 2,
            path: '/onboarding/create-workspace',
            icon: <MdDeliveryDining size={20} />,
        },
        {
            index: 3,
            path: '/onboarding/completed',
            icon: <FaCheck size={20} />,
        },
    ];

    const currentStep = SECTIONS.findIndex((section: any) => pathname.includes(section.path));

    return (
        <div className={styles.containerSteps}>
            <div className="fullWidth">
                <div className={styles.stepper}>
                    {
                        SECTIONS.map((section: any) => (
                            <div
                                key={section.index}
                                className={cs(styles.step, {
                                    [styles.active]: currentStep === section.index,
                                    [styles.active]: currentStep >= section.index,
                                    [styles.last]: section.index === SECTIONS.length - 1,
                                })}
                            >
                                <Avatar src={section.icon} className={styles.icon} />
                                <div className={cs(styles.line, {
                                    [styles.last]: section.index === (SECTIONS.length - 1),
                                })}
                                />
                            </div>
                        ))
                    }
                </div>
                <div className={styles.innerSection}>
                    { children }
                </div>
            </div>
        </div>
    );
};

export default Steps;
