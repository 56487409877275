import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import cs from 'classnames';

import {
    Avatar, Space, Button, Modal, notification, Table, Dropdown, Menu,
} from 'antd';
import { AiOutlinePlus, AiOutlineEllipsis } from 'react-icons/ai';
import { FiTrash } from 'react-icons/fi';

import HeaderSection from '../HeaderSection';

import styles from './styles.module.scss';
import IMAGES from '../../const/images';

import { RootState } from '../../reducers/root';
import { getDriversBySmallFleet, removeDriver, resendInvitation } from '../../api/drivers';
import { Driver } from '../../types/driver';

const { confirm } = Modal;

interface queryParamsProps {
  limit?: number,
  page: number,
  smallFleetID: string,
}

interface DriversListProps {
  showActions?: boolean,
  driverUpdated?: any,
}

interface MenuDriverProps {
  driver: Driver;
  resendInvitation: any;
  handleRemoveDriver: any;
}

const MenuDriver = ({ driver, resendInvitation, handleRemoveDriver }: MenuDriverProps) => {
    const { _id } = driver;
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });
    const { t: ta } = useTranslation('shared', { keyPrefix: 'ACTIONS' });
    const navigate = useNavigate();

    const items = [
        {
            children: ta('EDIT'),
            onClick: () => navigate(`/drivers/edit/${_id}`),
        },
        {
            children: td('RESEND_INVITATION'),
            onClick: () => resendInvitation(_id),
        },
        {
            children: ta('DELETE'),
            onClick: () => handleRemoveDriver(driver),
        },
    ];
    return (
        <Menu>
            {items.map((o, i) => (
                <Menu.Item key={i} {...o} />
            ))}
        </Menu>
    );
};

const DriversTable = ({ showActions, driverUpdated }: DriversListProps) => {
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });
    const { t: ta } = useTranslation('shared', { keyPrefix: 'ACTIONS' });

    const LIMIT = 10;
    const navigate = useNavigate();
    const params = useParams();

    const path = params['*']?.toString().split('/')[1];

    const [drivers, setDrivers] = useState<any>([]);

    const [maxDrivers, setMaxDrivers] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<any>({
        pageSize: LIMIT,
        page: 1,
    });

    const { active } = useSelector((state: RootState) => state.workspace);

    const getDrivers = () => {
        setLoading(true);

        const queryParams: queryParamsProps = {
            page: pagination.page,
            limit: pagination.pageSize,
            smallFleetID: active?._id,
        };

        getDriversBySmallFleet(queryParams)
            .then(({ body }: any) => {
                setMaxDrivers(active.drivers.length);
                setDrivers(body.drivers);
            })
            .catch((error: any) => console.log(error.message))
            .finally(() => setLoading(false));
    };

    const updateList = (driverData: Driver) => {
        const newDrivers = drivers.filter((driver: Driver) => driver?._id !== driverData?._id);
        setDrivers([]);
        setDrivers(newDrivers);

        if (path === driverData._id) {
            navigate('/drivers');
        }
    };

    const confirmRemoveDriver = (driverData: Driver) => {
        confirm({
            title: td('DELETE_QUESTION_DESCRIPTION', {
                name: driverData.name,
                lastName: driverData.lastName,
            }),
            content: td('DELETE_QUESTION'),
            icon: (
                <span role="img" aria-label="fi-trash">
                    <FiTrash color="#FF426F" size={24} />
                </span>
            ),
            okText: ta('DELETE'),
            okType: 'danger',
            onOk() {
                setLoading(true);
                const payload = {
                    userID: driverData._id,
                    smallFleetID: active?._id,
                };
                removeDriver(payload)
                    .then(() => {
                        notification.success({
                            message: td('DRIVER_REMOVED'),
                        });
                        updateList(driverData);
                    }).catch((error: any) => {
                        const description = error.response.data.message;
                        notification.error({
                            message: td('DRIVER_NO_REMOVED'),
                            description,
                        });
                    }).finally(() => setLoading(false));
            },
            onCancel() {
                console.log('Cancel delete driver');
            },
        });
    };

    const resendDriverInvitation = (driverID: string) => {
        setLoading(true);
        resendInvitation(driverID)
            .then(() => {
                notification.success({
                    message: td('RESEND_INVITATION'),
                    description: td('RESEND_INVITATION_SUCCESS'),
                });
            })
            .catch((error: any) => {
                const description = error.response.data.message;
                notification.error({
                    message: td('SEND_INVITATION_ERROR'),
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!active._id) return;
        getDrivers();
    }, [pagination]);

    useEffect(() => {
        if (driverUpdated) {
            const driverToUpdated = drivers.filter((driver: Driver) => driver?._id === path);

            if (driverToUpdated.length > 0) {
                driverToUpdated[0].name = driverUpdated.name;
                driverToUpdated[0].lastName = driverUpdated.lastName;
                driverToUpdated[0].countryCode = driverUpdated.countryCode;
                driverToUpdated[0].mobile = driverUpdated.mobile;
                driverToUpdated[0].fullMobile = `${driverUpdated.countryCode}${driverUpdated.mobile}`;
                driverToUpdated[0].email = driverUpdated.email;
            }

            const newDrivers = drivers.filter((driver: Driver) => driver?._id !== path);
            newDrivers.push(driverToUpdated[0]);
            setDrivers([]);
            setDrivers(newDrivers);
        }
    }, [driverUpdated]);

    const columns = [
        {
            title: td('FULL_NAME'),
            dataIndex: 'name',
            key: 'name',
            render: (name: string, driver: any) => `${name} ${driver.lastName}`,
        },
        {
            title: td('EMAIL'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: td('PHONE'),
            dataIndex: 'fullMobile',
            key: 'fullMobile',
            render: (fullMobile: string, driver: any) => (
                fullMobile
          || `${driver.countryCode} ${driver.mobile}`
          || td('NOT_AVAILABLE')
            ),
        },
        {
            title: td('STATUS'),
            dataIndex: 'status',
            key: 'status',
            render: (_: string, driver: any) => {
                const { isBusy, onDutyFlag } = driver;
                // eslint-disable-next-line no-nested-ternary
                const status = isBusy ? 'BUSY' : onDutyFlag ? 'ACTIVE' : 'DISCONNECTED';
                return (
                    <div className={cs('table-status', status)}>
                        {td(status)}
                    </div>
                );
            },
        },
        {
            title: td('ACTIONS'),
            dataIndex: '_id',
            key: '_id',
            render: (_id: string, driver: any) => (
                <Dropdown
                    overlay={(
                        <MenuDriver
                            driver={driver}
                            handleRemoveDriver={(driverData: Driver) => confirmRemoveDriver(driverData)}
                            resendInvitation={(driverID: string) => resendDriverInvitation(driverID)}
                        />
                    )}
                    trigger={['click']}
                    // @ts-ignore
                    onClick={(e: any) => { e.stopPropagation(); }}
                >
                    <Button ghost icon={<AiOutlineEllipsis size={24} style={{ color: 'black' }} />} />
                </Dropdown>
            ),
        },
    ];

    const onChange = (page: number, size: number) => {
        setPagination((prevPagination: any) => ({
            ...prevPagination,
            page,
            pageSize: size,
        }));
    };

    return (
        <div className="drivers">
            <Space direction="vertical" size={40}>
                <HeaderSection
                    title={td('MY_DRIVERS')}
                    icon={
                        <Avatar size={40} className="iconHeader" shape="square" src={IMAGES.icons.driver} />
                    }
                />

                {
                    showActions && (
                        <div className={styles.containerActions}>
                            <Space direction="horizontal" size={16}>
                                <Button
                                    className="flex-row center-row-center"
                                    type="primary"
                                    size="large"
                                    icon={<AiOutlinePlus size={24} className="cl--primary-white" />}
                                    onClick={() => navigate('/drivers/newDriver')}
                                />
                            </Space>
                        </div>
                    )
                }
                <Table
                    className="mp-table-card driver-table"
                    dataSource={drivers}
                    columns={columns}
                    scroll={{ x: 1000 }}
                    locale={{
                        filterConfirm: 'Ok',
                        filterReset: 'Reset',
                        emptyText: td('NO_DATA'),
                    }}
                    pagination={{
                        total: maxDrivers,
                        pageSize: pagination.pageSize,
                        current: pagination.page,
                        onChange,
                        showSizeChanger: true,
                    }}
                    rowKey="_id"
                    loading={loading}
                />
            </Space>
        </div>
    );
};

export default DriversTable;
