import {
    List, Skeleton, Button, Space, Avatar, Tooltip,
} from 'antd';
import { BsTrash } from 'react-icons/bs';
import { BiEdit } from 'react-icons/bi';
import { FaMobileAlt } from 'react-icons/fa';
import { MdOutlineMail } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp, IoMdOpen } from 'react-icons/io';
import { HiOutlineMail } from 'react-icons/hi';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import cs from 'classnames';
import styles from './styles.module.scss';

import { Driver, ImageDriver } from '../../types/driver';

interface ItemDriverProps {
  driver: Driver;
  id: string;
  loading: boolean;
  resendInvitation: any;
  handleRemoveDriver: any;
}

interface AvatarUserProps {
  onDutyFlag: boolean;
  isBusy: boolean;
  image: ImageDriver;
}

const ItemDriver = ({
    id,
    driver,
    loading,
    resendInvitation,
    handleRemoveDriver,
}: ItemDriverProps) => {
    const { t: ta } = useTranslation('shared', { keyPrefix: 'ACTIONS' });
    const { t: ts } = useTranslation('shared', { keyPrefix: 'SINGLES' });
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });

    const navigate = useNavigate();
    const params = useParams();
    const path = params['*'];
    const driverID = path?.toString().split('/')[1];

    const [driverActive, setDriverActive] = useState('');

    const handleActiveDriver = (driverID: string) => {
        if (driverActive !== '') {
            setDriverActive('');
        } else {
            setDriverActive(driverID);
        }
    };

    const preview = [
        <IoMdOpen size={20} color="#6C8EEF" onClick={() => navigate(`/drivers/view/${id}`)} />,
    ];

    const actions = [
        <Tooltip placement="bottom" title={`${ta('EDIT')} ${ts('DRIVER')}`}>
            <Button
                className={cs(
                    styles.button,
                    'flex-row center-row-center bg--neutral-neutral-3 noBorder button',
                )}
                type="ghost"
                icon={<BiEdit size={20} className="cl--secondary-purple" />}
                onClick={() => navigate(`/drivers/edit/${id}`)}
            />
        </Tooltip>,
        <Tooltip placement="bottom" title={td('RESEND_INVITATION')}>
            <Button
                className={cs(
                    styles.button,
                    'flex-row center-row-center bg--neutral-neutral-3 noBorder button',
                )}
                type="ghost"
                icon={<HiOutlineMail size={20} className="cl--secondary-purple" />}
                onClick={() => resendInvitation(id)}
            />
        </Tooltip>,
        <Tooltip placement="bottom" title={`${ta('DELETE')} ${ts('DRIVER')}`}>
            <Button
                className={cs(
                    styles.button,
                    'flex-row center-row-center bg--neutral-neutral-3 noBorder button',
                )}
                type="ghost"
                icon={<BsTrash size={20} className="cl--error-error" />}
                onClick={() => handleRemoveDriver(driver)}
            />
        </Tooltip>,
    ];

    const avatarUser = ({ onDutyFlag, isBusy, image }: AvatarUserProps) => {
        const dot = !isBusy && onDutyFlag ? 'success' : 'warning';

        return (
            <div className={styles.avatarUser}>
                <Avatar
                    size="large"
                    src={image.thumbnail}
                    className={styles.avatar}
                />
                <div className={cs(styles.dot, styles[dot])} />
            </div>
        );
    };

    const detailDriver = () => (
        <div className={cs(
            styles.moreInfo,
            { [styles.active]: driverActive === id },
        )}
        >
            <Space direction="vertical" size={16}>
                <span className={cs('bold left paragraph--3', styles.header)}>
                    { td('CONTACT') }
                </span>
                <Space direction="vertical" size={8}>
                    <List.Item.Meta
                        avatar={<FaMobileAlt className="cl--primary-dark-blue-3" size={20} />}
                        title={(
                            <span className="left medium paragraph--3">
                                {
                                    driver.fullMobile
                  || `${driver.countryCode} ${driver.mobile}`
                  || td('NOT_AVAILABLE')
                                }
                            </span>
                        )}
                    />
                    <List.Item.Meta
                        avatar={<MdOutlineMail className="cl--primary-dark-blue-3" size={20} />}
                        title={(
                            <span className="left medium paragraph--3">
                                { driver.email }
                            </span>
                        )}
                    />
                </Space>
                <span className={cs('bold left paragraph--3', styles.header)}>
                    { td('ACTIONS') }
                </span>
                <div className={cs(styles.containerActions, 'flex-row start-row-center fullWidth')}>
                    {
                        actions.map((action: any) => (
                            action
                        ))
                    }
                </div>
            </Space>
        </div>
    );

    return (
        <div className={cs(
            styles.itemDriver,
            { [styles.selected]: driverID === id },
        )}
        >
            <List.Item
                id={id}
                actions={preview}
                className={cs('fullWidth', styles.content)}
            >
                <Skeleton avatar title={false} loading={loading} active>
                    <List.Item.Meta
                        avatar={
                            avatarUser({
                                onDutyFlag: driver.onDutyFlag,
                                isBusy: driver.isBusy,
                                image: driver.image,
                            })
                        }
                        title={(
                            <Space size={16} direction="vertical">
                                <span className="medium paragraph--3">
                                    {`${driver.name} ${driver.lastName}`}
                                </span>
                            </Space>
                        )}
                        description={(
                            <div
                                className={cs('flex-row start-row-center', styles.more)}
                                onClick={() => handleActiveDriver(id)}
                            >
                                {
                                    driverActive === id && driverActive !== ''
                                        ? <IoIosArrowUp color="#6C8EEF" size={16} className={styles.icon} />
                                        : <IoIosArrowDown color="#6C8EEF" size={16} className={styles.icon} />
                                }
                                <span className="cl--secondary-purple medium">
                                    { driverActive === id && driverActive !== '' ? ta('SEE_LESS') : ta('SEE_MORE') }
                                </span>
                            </div>
                        )}
                    />
                </Skeleton>
            </List.Item>
            { detailDriver() }
        </div>
    );
};

export default ItemDriver;
