import { Select, Space, Avatar } from 'antd';

import { COUNTRY_CODES } from '../../const/countryCodes';

const { Option } = Select;

interface ICountryCodeSelector {
  // eslint-disable-next-line no-unused-vars
  handleSelect: (value: string) => void;
  defaultCountryCode?: string;
  styles: any;
}

const CountryCodeSelector = ({
    handleSelect, defaultCountryCode, styles,
}: ICountryCodeSelector) => (
    <Select
        notFoundContent={null}
        showSearch
        placeholder="+593"
        defaultValue="+593"
        className={styles.countryCode}
        onChange={handleSelect}
        value={defaultCountryCode}
    >
        {
            COUNTRY_CODES.map((cc, index: number) => (
                <Option
                    key={index}
                    value={`+${cc.callingCodes}`}
                    label={`+${cc.callingCodes}`}
                    className={styles.option}
                >
                    <Space direction="horizontal" size={8}>
                        <Avatar
                            src={cc.flag}
                            size={24}
                            alt={`+${cc.callingCodes}`}
                            className={styles.flag}
                        />
                        <span>
                            {`+${cc.callingCodes}`}
                        </span>
                    </Space>
                </Option>
            ))
        }
    </Select>
);

export default CountryCodeSelector;
