import { Col, Row, Space, Form, Typography, Input, Checkbox } from 'antd';
import cs from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { TDefaultLogo } from '@/types/workspace';
import { SmallFleet } from '@/components/Icons/SmallFleet';

import styles from './styles.module.scss';
import { LOGOS } from '@/const/logos';
import { useTranslation } from 'react-i18next';

const defaultLogos: TDefaultLogo[] = LOGOS.driver;

interface IDefaultLogo {
  setDefaultLogo?: (params: string) => void
  existDefaultLogo?: null | string;
}

const DefaultLogo = ({
  setDefaultLogo,
  existDefaultLogo
}: IDefaultLogo) => {
  const [checked, setChecked] = useState('');

  const { t: cw } = useTranslation('onboarding', { keyPrefix: 'CREATE_WORKSPACE' });

  const handleOnChange = (value: string) => {
    setChecked(value);
    setDefaultLogo && setDefaultLogo(value);
    return value;
  };

  useEffect(() => {
    if(existDefaultLogo) {
      setChecked(existDefaultLogo);
      setDefaultLogo && setDefaultLogo(existDefaultLogo);
    }
  }, [existDefaultLogo]);

  return (
    <Space direction="vertical">
      <span style={{ marginBottom: "16px" }} className="center medium centdelivery-partner/createSmallFleeter paragraph--3 cl--secondary-purple">
        {cw('DONT_HAVE_A_LOGO_DESCRIPTION')}
      </span>
      <Row justify="space-evenly">
        {defaultLogos.map(logo =>
          <Col
            className={
              cs(
                styles.logoContainer
              )
            }
            style={checked === logo.type ? {background: logo.background} : {}}
            onClick={() => handleOnChange(logo.type)}
          >
            <div className="flex-column center-column-center">
              <SmallFleet size={72} color={logo.color} fleetBackground={checked === logo.type ? logo.background : ""} />
            </div>
          </Col>
        )}
      </Row>
    </Space>
  )
}

export default DefaultLogo