import {
    Row, Col, Button, Form, Input, Select, notification, Tooltip,
} from 'antd';

import { AxiosError } from 'axios';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import countryCodes from '@/const/country-codes';
import SelectLogoContent from '@/components/Onboarding/SelectLogoContent';
import { editWorkspace } from '@/api/workspace';
import { getRegionsApi } from '@/api/country';

import styles from '@/styles/screens/Settings.module.scss';
import TagRender from '@/components/TagRender';
import { changeWorkspace } from '@/actions/workspace';
import { generateDataToUpdate } from '../utils/account';

interface CountryCodes {
  flag: string;
  name: string;
  callingCodes: string[]
}

const Account = () => {
    const dispatch = useDispatch<any>();
    const { t: ta } = useTranslation('shared', { keyPrefix: 'ACTIONS' });
    const { t: ra } = useTranslation('shared', { keyPrefix: 'RESPONSE_ACTIONS' });
    const { t: mq } = useTranslation('onboarding', { keyPrefix: 'MEASURE_QUESTIONS' });
    const { t: ca } = useTranslation('onboarding', { keyPrefix: 'CREATE_ACCOUNT' });

    const [form] = Form.useForm();
    const [defaultLogo, setDefaultLogo] = useState<null | string>('');
    const [isLogoUploaded, setLogoUploaded] = useState<null | string>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountry] = useState<CountryCodes[]>([]);
    const [cities, setCities] = useState<string[]>([]);
    const [countryCodeName, setCountryCodeName] = useState('');

    const { workspace: { active } } = useSelector((state: any) => state);

    const selectCities = (value: string) => {
        if (value !== active.country) form.setFieldValue('coverageCities', []);

        setCities([]);

        const currentCoutryCode = countryCodes.find((country) => country.name === value);

        if (currentCoutryCode) {
            setCountryCodeName(currentCoutryCode.countryCodeName || '');
        }

        notification.destroy();

        getRegionsApi(value)
            .then((response: any) => {
                const citiesSorted = response.data.sort();
                setCities(citiesSorted);
            })
            .catch((error: any) => {
                const code = get(error, 'response.status', 500);
                const message = code === 404 ? ra('COUNTRY_NOT_FOUND') : ra('ERROR_OCURRED');
                notification.error({
                    message,
                });
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        form.setFieldsValue({
            country: active.country,
            coverageCities: active.coverageCities,
            coverageDistance: active.coverageDistance,
        });
        setCountryCodeName(active.countryCodeName);
        const enabled = countryCodes.filter((country) => country.countryCodeName);
        setCountry(enabled);
        selectCities(active.country);
    }, []);

    const onSubmitInfo = async () => {
        setIsLoading(true);
        form
            .validateFields()
            .then(async (values) => {
                const dataToUpdate = await generateDataToUpdate({
                    formValues: { ...values, countryCodeName },
                    activeWorkspace: active,
                    defaultLogo,
                    isLogoUploaded,
                    setLogoUploaded,
                });

                try {
                    await editWorkspace(dataToUpdate);
                    dispatch(changeWorkspace({
                        ...active,
                        ...dataToUpdate,
                    }));
                    notification.success({
                        message: ra('INFORMATION_UPDATED'),
                    });
                } catch (error: any) {
                    if (error instanceof AxiosError) {
                        notification.error({
                            message: ra('ERROR'),
                            description: error.response?.data?.message,
                        });
                    }
                }
            })
            .finally(() => setIsLoading(false));
    };

    const lightTooltip = (help: string) => (
        <div className={styles.containerHelp}>
            {mq(help)}
        </div>
    );

    const helpLabel = (label: string, help: string) => (
        <div className="flex-row start-row-center">
            <span className="cl--secondary-purple" style={{ marginRight: 8 }}>
                {mq(label)}
            </span>
            <Tooltip
                placement="right"
                overlay={lightTooltip(help)}
                color="#1A1F36"
                style={{ maxWidth: 320 }}
                overlayClassName={styles.tooltip}
                trigger={['click', 'hover']}
            >
                <BsFillInfoCircleFill />
            </Tooltip>
        </div>
    );

    const containerForm = () => (
        <Form form={form} disabled={isLoading} onFinish={onSubmitInfo} layout="vertical" style={{ width: '100%' }} id="1">
            <Row gutter={[64, 40]} justify="space-between" align="top">
                <Col lg={12} md={24}>
                    <Form.Item
                        key="country"
                        name="country"
                        label={helpLabel('COUNTRY', 'COUNTRY_HELP')}
                        className={styles.formInput}
                        rules={[{ required: true, message: ca('FIELD_REQUIRED') }]}
                    >
                        <Select
                            onSelect={selectCities}
                            size="large"
                            className="signup-select"
                            showSearch
                        >
                            {countries.map((country: any) => (
                                <Select.Option
                                    key={`${country.name}${country.callingCodes[0]}`}
                                    value={country.name}
                                    showSearch
                                >
                                    <div className={styles.countrySelect}>
                                        <img
                                            src={country.flag}
                                            alt={country.name}
                                            className={styles.flag}
                                            aria-label={`${country.name[0]}`}
                                        />
                                        {country.name}
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        key="coverageCities"
                        name="coverageCities"
                        label={helpLabel('COVERAGE_CITIES', 'COVERAGE_CITIES_HELP')}
                        className={styles.formInput}
                        rules={[{ required: true, message: ca('FIELD_REQUIRED') }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            size="large"
                            className="signup-select"
                            tagRender={TagRender}
                            placeholder={cities.length === 0 ? 'Select a country...' : 'Select cities...'}
                        >
                            {cities && cities.map((city: any) => (
                                <Select.Option
                                    key={city}
                                    value={city}
                                >
                                    {city}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        key="coverageDistance"
                        name="coverageDistance"
                        label={helpLabel('MAXIMUM_DELIVERY_DISTANCE', 'MAXIMUM_DELIVERY_DISTANCE_HELP')}
                        className={styles.formInput}
                        rules={[{ required: true, message: ca('FIELD_REQUIRED') }]}
                    >
                        <Input
                            size="large"
                            type="number"
                            placeholder={mq('MAXIMUM_DELIVERY_DISTANCE_PLACEHOLDER')}
                            onKeyDown={(event) => {
                                const re = /^[0-9\b]+$/;
                                if (!re.test(event.key) && event.key !== 'Backspace') {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>

                </Col>
                <Col lg={12} md={24}>
                    <SelectLogoContent setDefaultLogo={setDefaultLogo} />
                </Col>
            </Row>
        </Form>
    );

    return (
        <div className={styles.containerAccount}>
            {containerForm()}
            <Button
                type="primary"
                htmlType="submit"
                className={styles.button}
                style={{ margin: '24px auto' }}
                loading={isLoading}
                onClick={() => form.submit()}
            >
                {ta('UPDATE')}
            </Button>
        </div>
    );
};

export default Account;
