import {
    Routes,
    Route,
} from 'react-router-dom';

import DriverDetail from '@/components/DriverDetail';
import UpdateDriver from '@/components/UpdateDriver';
import Home from '../screens/Home';
import BookingDetail from '../screens/BookingDetail';
import Drivers from '../screens/Drivers';
import NewDriver from '../screens/NewDriver';
import Settings from '../screens/Settings';
import HistoryBookings from '../screens/HistoryBookings';
import ActiveBookings from '../screens/ActiveBookings';

const DashboardRouter = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="bookings">
            <Route path="actives" element={<ActiveBookings />} />
            <Route path="history" element={<HistoryBookings />} />
            <Route path=":id" element={<BookingDetail />} />
        </Route>
        <Route path="drivers">
            <Route path="" element={<Drivers />} />
            <Route
                path="edit/:id"
                element={
                    <UpdateDriver />
                }
            />
            <Route
                path="view/:id"
                element={
                    <DriverDetail />
                }
            />
            <Route path="newDriver" element={<NewDriver />} />
        </Route>
        <Route path="/drivers/*" element={<Drivers />} />
        <Route path="/settings" element={<Settings />} />
    </Routes>
);
export default DashboardRouter;
