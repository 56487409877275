import {
    Form, Space, Alert, Input, Button, notification,
} from 'antd';
import { useState } from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from '@/styles/screens/Onboarding.module.scss';
import { uploadWorkspaceLogo } from '@/api/workspace';
import { TDefaultLogo } from '@/types/workspace';
import { LOGOS } from '@/const/logos';
import { setError } from '@/actions/ui';

import SelectLogoContent from '../../SelectLogoContent';

interface ICreateWorkspace {
  form: any;
  workspaceData: any;
  // eslint-disable-next-line no-unused-vars
  setWorkspaceData: (values: any) => void;
  handleFormData: () => void;
  loading: boolean
}

const CreateWorkspace = ({
    form,
    workspaceData,
    setWorkspaceData,
    handleFormData,
    loading,
}: ICreateWorkspace) => {
    const { t: cw } = useTranslation('onboarding', { keyPrefix: 'CREATE_WORKSPACE' });
    const { t: ob } = useTranslation('onboarding');
    const [defaultLogo, setDefaultLogo] = useState<null | string>('');
    const [isLogoUploaded, setLogoUploaded] = useState<null | string>(null);
    const { ui: { msgError } } = useSelector((state: any) => state);

    const onSubmit = async () => {
        await form.validateFields();

        let image: string = '';
        let color: string = '6C8EEF';
        setError(null);

        try {
            const {
                logo,
                name,
            } = await form.getFieldsValue();

            if (!defaultLogo && !isLogoUploaded && !logo) {
                notification.error({
                    message: cw('LOGO_IS_REQUIRED'),
                    description: cw('LOGO_IS_REQUIRED_DESCRIPTION'),
                });
                return;
            }

            if (logo && logo.length > 0) {
                if (isLogoUploaded) {
                    image = isLogoUploaded;
                } else {
                    const uploadRes = await uploadWorkspaceLogo(logo[0].originFileObj);
                    setLogoUploaded(uploadRes.data.thumbnail);
                    image = uploadRes.data.thumbnail;
                }
            }

            if (defaultLogo && !logo) {
                const defaultLogoFind: TDefaultLogo | undefined = LOGOS.driver.find((logo) => logo.type === defaultLogo);

                if (defaultLogoFind) {
                    image = defaultLogoFind.url;
                    color = defaultLogoFind.color;
                }
            }
            setWorkspaceData({
                ...workspaceData,
                name,
                image,
                color,
            });
            setTimeout(() => {
                handleFormData();
            }, 100);
        } catch (error: any) {
            setError(error.response.data.message);
        }
    };

    return (
        <div className={styles.innerSection}>
            <Form form={form} layout="vertical" className={styles.form} disabled={loading}>
                <Space direction="vertical" size={24}>
                    <div className={styles.header}>
                        <span className="title--header-2 cl--secondary-purple">
                            {cw('NAME_DELIVERY')}
                        </span>
                    </div>
                    <span className="paragraph--2 center">
                        {cw('NAME_DELIVERY_DESCRIPTION')}
                    </span>
                    {msgError && (
                        <Alert message={msgError} type="error" />
                    )}
                    <Form.Item
                        name="name"
                        className={styles.formInput}
                        rules={[
                            {
                                required: true,
                                message: ob('FIELD_REQUIRED'),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder={cw('NAME_DELIVERY_PLACEHOLDER')}
                        />
                    </Form.Item>
                    <SelectLogoContent setDefaultLogo={setDefaultLogo} showTitle />
                    <Button
                        type="primary"
                        className={cs(styles.button, 'lg')}
                        loading={loading}
                        onClick={onSubmit}
                    >
                        {cw('FINALIZE')}
                    </Button>
                </Space>
            </Form>
        </div>
    );
};

export default CreateWorkspace;
