/* eslint-disable import/no-unresolved */
import { Tabs, Space } from 'antd';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BiCog } from 'react-icons/bi';
import { General } from '@/components/Settings';
import HeaderSection from '@/components/HeaderSection';

const { TabPane } = Tabs;

interface ISettingsTab {
  key: number;
  name: string;
  total: number;
}

const Settings = () => {
    const { t: tp } = useTranslation('shared', { keyPrefix: 'PAGES' });
    const { t: ts } = useTranslation('settings', { keyPrefix: 'SETTINGS' });

    const [tabActive, setTabActive] = useState(1);

    const SETTINGS_TABS : ISettingsTab[] = [
        {
            key: 1,
            name: ts('TABS.GENERAL'),
            total: 0,
        },
    ];

    return (
        <div className="contentLayouts fullWidth fullHeight bg--primary-white">
            <Space direction="vertical" size={64} className="fullWidth">
                <Space direction="vertical" size={32} className="fullWidth">
                    <HeaderSection
                        title={tp('SETTINGS')}
                        icon={<BiCog size={40} className="iconHeader" />}
                    />
                    <Tabs
                        onChange={(tab: any) => setTabActive(Number(tab))}
                        defaultActiveKey={tabActive.toString()}
                        size="small"
                        type="card"
                    >
                        {
                            SETTINGS_TABS.map((tab: any) => (
                                <TabPane tab={tab.name} key={tab.key} />
                            ))
                        }
                    </Tabs>
                    <General />
                </Space>
            </Space>
        </div>
    );
};

export default Settings;
