import { uploadWorkspaceLogo } from '@/api/workspace';
import { LOGOS } from '@/const/logos';
import { TDefaultLogo } from '@/types/workspace';
import { Dispatch, SetStateAction } from 'react';

type TGenerateDataToUpdate = {
  formValues: any;
  activeWorkspace: any;
  defaultLogo: string | null;
  isLogoUploaded: string | null;
  setLogoUploaded: Dispatch<SetStateAction<string | null>>;
}

const generateDataToUpdate = async ({
    formValues,
    activeWorkspace,
    defaultLogo,
    isLogoUploaded,
    setLogoUploaded,
}: TGenerateDataToUpdate) => {
    const { image: currentImage, color: currentColor } = activeWorkspace;
    let image: string = currentImage;
    let color: string = currentColor;

    if (formValues.logo && formValues.logo.length > 0) {
        if (isLogoUploaded) {
            image = isLogoUploaded;
        } else {
            const uploadRes = await uploadWorkspaceLogo(formValues.logo[0].originFileObj);
            setLogoUploaded(uploadRes.data.thumbnail);
            image = uploadRes.data.thumbnail;
        }
    }

    if (!formValues.logo && defaultLogo) {
        const defaultLogoFind: TDefaultLogo | undefined = LOGOS.driver.find(
            (logo) => logo.type === defaultLogo,
        );

        if (defaultLogoFind) {
            image = defaultLogoFind.url;
            color = defaultLogoFind.color;
        }
    }

    return {
        smallFleetID: activeWorkspace._id,
        country: formValues.country,
        countryCodeName: formValues.countryCodeName,
        coverageCities: formValues.coverageCities,
        coverageDistance: formValues.coverageDistance,
        image,
        color,
    };
};

export {
    generateDataToUpdate,
};
