/* eslint-disable no-unused-vars */
import moment from 'moment';
import esES from 'antd/es/locale/es_ES';
import enUS from 'antd/es/locale/en_US';

import {
    Col,
    Row,
    ConfigProvider,
    Input,
    DatePicker,
    Button,
} from 'antd';
import { BsHash, BsSearch } from 'react-icons/bs';
import { IoReloadOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

interface Props {
    loading: boolean;
    search?: string;
    handleSearch?: (e: any) => void;
    numericId?: string;
    handleNumericId?: (e: any) => void;
    startDate?: string;
    endDate?: string;
    handleDate?: (e: any) => void;
    refreshData: () => void;
}

const { RangePicker }: any = DatePicker;

const Filters = ({
    loading,
    search,
    handleSearch,
    numericId,
    handleNumericId,
    startDate,
    endDate,
    handleDate,
    refreshData,
}: Props) => {
    const { t, i18n } = useTranslation('bookings', { keyPrefix: 'FILTERS' });

    return (
        <Row className="home__filters" gutter={[16, 16]} justify="start" align="top">
            {/* <Col className="home__filters--col-search">
                <Input
                    style={{ width: 300 }}
                    size="small"
                    allowClear
                    className="home__input--search"
                    placeholder={t('SEARCH')}
                    prefix={<BsSearch className="home__input-icon" />}
                    onChange={handleSearch}
                    value={search}
                    disabled={loading}
                />
            </Col> */}
            <Col className="home__filters--col-numeric">
                <ConfigProvider locale={i18n.language === 'en' ? enUS : esES}>
                    <RangePicker
                        defaultValue={startDate ? [moment(startDate), moment(endDate)] : null}
                        onChange={(date: any = []) => {
                            if (handleDate) {
                                if (date) {
                                    const [startDate, endDate] = date;
                                    handleDate([
                                        startDate.startOf('day').toISOString(),
                                        endDate.endOf('day').toISOString(),
                                    ]);
                                } else {
                                    handleDate([
                                        startDate,
                                        endDate,
                                    ]);
                                }
                            }
                        }}
                    />

                </ConfigProvider>
            </Col>
            <Col className="filters__col-numeric">
                <Input
                    style={{ width: 210 }}
                    size="small"
                    allowClear
                    className="filters__input--search numeric-id"
                    placeholder={t('SEARCH_BY_NUMERIC_ID')}
                    prefix={<BsHash className="home__input-icon" />}
                    onChange={handleNumericId}
                    value={numericId}
                    disabled={loading}
                    maxLength={9}
                />
            </Col>
            <Col>
                <Button
                    type="ghost"
                    icon={<IoReloadOutline style={{ verticalAlign: 'middle' }} />}
                    onClick={() => refreshData()}
                />
            </Col>
        </Row>
    );
};

export default Filters;
