export const STATUSES = {
    PENDING: 'PENDING',
    ON_DECK: 'ON_DECK',
    READY_FOR_PICKUP: 'READY_FOR_PICKUP',
    ARRIVED_AT_PICKUP: 'ARRIVED_AT_PICKUP',
    WAY_TO_DELIVER: 'WAY_TO_DELIVER',
    ARRIVED_AT_DELIVERY: 'ARRIVED_AT_DELIVERY',
    ACCEPTED: 'ACCEPTED',
    ENROUTE_TO_PICKUP: 'ENROUTE_TO_PICKUP',
    PICKED_UP: 'PICKED_UP',
    ENROUTE_TO_DELIVERY: 'ENROUTE_TO_DELIVERY',
    DELIVERED: 'DELIVERED',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    CANCELLED_BY_DRIVER: 'CANCELLED_BY_DRIVER',
    CANCELLED_BY_CUSTOMER: 'CANCELLED_BY_CUSTOMER',
    CANCELLED_BY_SERVICE_PROVIDER: 'CANCELLED_BY_SERVICE_PROVIDER',
    CANCELLED_BY_ADMIN: 'CANCELLED_BY_ADMIN',
    EXPIRED: 'EXPIRED',
    ON_HOLD: 'ON_HOLD',
    REJECTED_BY_DRIVER: 'REJECTED_BY_DRIVER',
    REJECTED_BY_CUSTOMER: 'REJECTED_BY_CUSTOMER',
    REJECTED_BY_BUSINESS: 'REJECTED_BY_BUSINESS',
    CANCELLED_REQUEST: 'CANCELLED_REQUEST',
    CANCELLED_BY_BUSINESS: 'CANCELLED_BY_BUSINESS',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    TIME_PASSED: 'Booking time is already passed',
    NOT_DELIVERED: 'NOT_DELIVERED',
};

export const FINISHED = [
    STATUSES.COMPLETED,
    STATUSES.CANCELLED_BY_ADMIN,
    STATUSES.CANCELLED,
    STATUSES.CANCELLED_BY_DRIVER,
    STATUSES.REJECTED_BY_DRIVER,
    STATUSES.REJECTED_BY_CUSTOMER,
    STATUSES.REJECTED_BY_BUSINESS,
    STATUSES.CANCELLED_REQUEST,
    STATUSES.CANCELLED_BY_BUSINESS,
    STATUSES.PAYMENT_FAILED,
    STATUSES.NOT_DELIVERED,
];

export const SMR_AVAILABLE = ['ON_DECK', 'ON_HOLD', 'ACCEPTED', 'READY_FOR_PICKUP', 'ARRIVED_AT_PICKUP', 'WAY_TO_DELIVER', 'ARRIVED_AT_DELIVERY'];

export const HEADER_STATUSES = {
    READY_FOR_PICKUP: {
        label: 'SEARCHING',
        label_help: 'SEARCHING_HELP',
        color: 'primary',
    },
    ACCEPTED: {
        label: 'COLLECTING',
        label_help: 'COLLECTING_HELP',
        color: 'purple',
    },
    DELIVERING: {
        label: 'DELIVERING',
        label_help: 'DELIVERING_HELP',
        color: 'blue',
    },
    COMPLETED: {
        label: 'COMPLETED',
        label_help: 'COMPLETED_HELP',
        color: 'green',
    },
};
