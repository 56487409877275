import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { phone } from 'phone';
import { IFieldProps } from './interface';

import CountryCode from './CountryCodeSelector';
import InputPhone from './InputPhone';
import { CountryCodes } from '../../enum/countryCode.enum';

interface PhoneNumberProps {
  form: any;
  fieldProps: IFieldProps;
  // eslint-disable-next-line no-unused-vars
  handleSelect: (value: string) => void;
  defaultCountryCode?: string;
  styles?: any;
}

const PhoneNumber = ({
    form, fieldProps, handleSelect, defaultCountryCode, styles,
}: PhoneNumberProps) => {
    const { t } = useTranslation('onboarding');

    const [phoneError, setPhoneError] = useState<string[]>([]);

    const handleError = () => setPhoneError(form.getFieldError(fieldProps.keyMobile));

    const validator = (_: any, value: string) => {
        const countryCode = form.getFieldValue(fieldProps.keyCountryCode);

        let validateMobilePrefix = true;

        if (countryCode === `+${CountryCodes.GT}` || countryCode === `+${CountryCodes.CR}`) {
            validateMobilePrefix = false;
        }

        const phoneNumber = `${countryCode}${value}`;

        const { isValid } = phone(phoneNumber, { validateMobilePrefix });

        if (!value) setPhoneError([t('FIELD_REQUIRED')]);
        else if (!isValid) setPhoneError([t('PHONE_NUMBER_INVALID')]);
        else setPhoneError([]);

        return isValid ? Promise.resolve() : Promise.reject(t('PHONE_NUMBER_INVALID'));
    };

    const handleCountryCode = (value: string) => {
        handleSelect(value);
        const mobileTouched = form.isFieldTouched(fieldProps.keyMobile);
        if (mobileTouched) {
            (document.querySelector(`#${fieldProps.keyMobile}`) as HTMLInputElement).focus();
        }
    };

    return (
        <div className={styles.containerPhoneNumber}>
            <label
                className={styles.label}
                htmlFor={fieldProps.keyMobile}
            >
                {fieldProps.label}
            </label>

            <div className={styles.containerFields}>

                <div className={styles.containerCountryCode}>
                    <CountryCode
                        defaultCountryCode={defaultCountryCode}
                        handleSelect={handleCountryCode}
                        styles={styles}
                    />
                    <Form.Item hidden name={fieldProps.keyCountryCode}>
                        <Input />
                    </Form.Item>
                </div>

                <div className={styles.containerMobile}>
                    <div className={styles.mobile}>
                        <Form.Item
                            name={fieldProps.keyMobile}
                            noStyle
                            rules={[{ validator }]}
                            validateTrigger={['onBlur', 'onChange']}
                        >
                            <InputPhone
                                onChange={handleError}
                                styles={styles}
                                phoneError={phoneError}
                                id={fieldProps.keyMobile}
                            />
                        </Form.Item>
                    </div>

                    <div className={styles.containerErrors}>
                        {
                            phoneError.length > 0
                        && (
                            phoneError.map((error: string) => (
                                <span className={styles.error}>
                                    {error}
                                </span>
                            ))
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhoneNumber;
