import axios from 'axios';

export const getCountriesApi = () => {
    const COUNTRY_URL = 'https://restcountries.com';
    const route = '/v3.1/region/ame';
    const url = `${COUNTRY_URL}${route}`;
    return axios.get(url);
};

export const getRegionsApi = (country: string) => {
    const REGION_URL = 'https://countriesnow.space/api/v0.1';
    const route = '/countries/cities';
    const body = { country };
    const url = `${REGION_URL}${route}`;
    return axios.post(url, body);
};
