import { Space, Avatar } from 'antd';
import {
    Route,
    Routes,
    useLocation,
} from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import { MdDeliveryDining } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { IoInformation } from 'react-icons/io5';

import cs from 'classnames';
import CreateWorkspaceContent from '@/components/Onboarding/CreateWorkspaceContent';
import styles from '../styles/screens/Onboarding.module.scss';

import IMAGES from '../const/images';
import {
    Footer, Header, Subheader, Completed,
} from '../components/Onboarding';

const OnBoarding = () => {
    const { pathname } = useLocation();

    const SECTIONS = [
        {
            index: 0,
            path: '/onboarding/create-account',
            icon: <AiOutlineUser size={20} />,
        },
        {
            index: 1,
            path: '/onboarding/about-you',
            icon: <IoInformation size={20} />,
        },
        {
            index: 2,
            path: '/onboarding/create-workspace',
            icon: <MdDeliveryDining size={20} />,
        },
        {
            index: 3,
            path: '/onboarding/completed',
            icon: <BsCheck size={20} />,
        },
    ];

    const currentStep = SECTIONS.findIndex((section: any) => pathname.includes(section.path));

    return (
        <div
            style={{ backgroundImage: `url(${IMAGES.login.spirals})` }}
            className={cs(styles.onboarding, 'flex-column center-column-center')}
        >
            <Space direction="vertical" size={24}>
                <Header />
                <Subheader />

                <div className={styles.containerSteps}>
                    <Routes>
                        <Route
                            path="*"
                            element={(
                                <div className="fullWidth">
                                    <div className={styles.stepper}>
                                        {
                                            SECTIONS.map((section: any) => (
                                                <div
                                                    key={section.index}
                                                    className={cs(styles.step, {
                                                        [styles.active]: currentStep === section.index,
                                                        [styles.active]: currentStep >= section.index,
                                                        [styles.last]: section.index === SECTIONS.length - 1,
                                                    })}
                                                >
                                                    <Avatar src={section.icon} className={styles.icon} />
                                                    <div className={cs(styles.line, {
                                                        [styles.last]: section.index === (SECTIONS.length - 1),
                                                    })}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <Routes>
                                        <Route path="create-workspace" element={<CreateWorkspaceContent />} />
                                        <Route path="completed" element={<Completed />} />
                                    </Routes>
                                </div>
                            )}
                        />
                    </Routes>
                </div>
                <Footer />
            </Space>
        </div>
    );
};

export default OnBoarding;
