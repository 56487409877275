export const PAYMENT_METHODS = {
    CARD: 'CARD',
    CASH: 'CASH',
    NONE: 'NONE',
    BILLING_CARD: 'BILLING_CARD',
    BILLING_TRANSFER: 'BILLING_TRANSFER',
};

export const getPaymentMethodLabel = (value: string) => {
    switch (value) {
    case PAYMENT_METHODS.CARD:
    case PAYMENT_METHODS.BILLING_CARD:
    case PAYMENT_METHODS.BILLING_TRANSFER:
        return 'CARD';
    case PAYMENT_METHODS.CASH:
        return 'CASH';
    default:
        return '';
    }
};
