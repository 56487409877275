import { Select } from 'antd';
import cs from 'classnames';

import { IoCaretDownSharp } from 'react-icons/io5';
import { AiOutlineGlobal } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const SwitchLanguage = () => {
    const { t: l, i18n } = useTranslation('shared', { keyPrefix: 'LANGUAGES' });

    const changeLanguage = (locale: string) => {
        i18n.changeLanguage(locale);
    };

    return (
        <div className={styles.containerSwitcher}>
            <div className={styles.languageIcon}>
                <AiOutlineGlobal />
            </div>
            <Select
                className={cs(styles.selectLanguageContainer, 'language-selector')}
                value={i18n.resolvedLanguage}
                onChange={changeLanguage}
                suffixIcon={<IoCaretDownSharp color="#3F4D70" size={10} />}
            >
                <Select.Option value="es">
                    <div className={styles.flagContainer}>
                        {l('SPANISH')}
                    </div>
                </Select.Option>
                <Select.Option value="en">
                    <div className={styles.flagContainer}>
                        {l('ENGLISH')}
                    </div>
                </Select.Option>
            </Select>
        </div>
    );
};

export default SwitchLanguage;
