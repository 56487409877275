import {
    Avatar, Row, Col, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Driver } from '../../types/driver';
import styles from './styles.module.scss';

interface DriverCardProps {
  driver: Driver,
  title: string,
  icon: any,
  type: string,
}

const DriverCard = ({
    driver, title, icon, type,
} : DriverCardProps) => {
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });
    const { t: ca } = useTranslation('onboarding', { keyPrefix: 'CREATE_ACCOUNT' });
    const { t: ts } = useTranslation('shared', { keyPrefix: 'TABLE' });

    const data : any = {
        DRIVER: [
            {
                label: ca('FIRST_NAME'),
                value: driver.name,
            },
            {
                label: ca('LAST_NAME'),
                value: driver.lastName,
            },
            {
                label: ca('EMAIL'),
                value: driver.email,
            },
            {
                label: ca('PHONE'),
                value: `${driver.countryCode} ${driver.mobile}` || driver.mobile || driver.fullMobile,
            },
            {
                label: td('LANGUAGE'),
                value: driver.language,
            },
            {
                label: td('CURRENCY'),
                value: driver.currency,
            },
        ],
        VEHICLE: [
            {
                label: td('VEHICLE_MODEL_NAME'),
                value: driver.vehicleModelName,
            },
            {
                label: td('MC_NUMBER'),
                value: driver.MCNumber,
            },
            {
                label: td('VEHICLE_TYPE'),
                value: driver.vehicleType,
            },
            {
                label: td('VEHICLE_YEAR'),
                value: driver.vehicleYear,
            },
            {
                label: td('VEHICLE_COLOR'),
                value: driver.vehicleColor,
            },
        ],
    };

    const header = () => (
        <div className={styles.header}>
            <Avatar
                className={styles.avatar}
                size={56}
                src={icon}
            />
            <div className={styles.title}>
                <span className="cl--primary-white medium paragraph--3">{title}</span>
            </div>
        </div>
    );

    return (
        <div className={styles.driverCard}>
            { header() }
            <Row justify="start" align="top" gutter={[24, 24]} className="noMargin">
                <Col xs={24} md={12}>
                    <Space direction="vertical" size={24}>
                        {
                            data[type].map((info: any, index: number) => (
                                index % 2 === 0 && (
                                    <Space size={8} direction="vertical">
                                        <span className="bold cl--secondary-purple paragraph--3">
                                            {info.label}
                                        </span>
                                        <span className="medium cl--primary-dark-blue paragraph--3">
                                            {info.value || ts('NOT_AVAILABLE')}
                                        </span>
                                    </Space>
                                )
                            ))
                        }
                    </Space>
                </Col>
                <Col xs={24} md={12}>
                    <Space direction="vertical" size={24}>
                        {
                            data[type].map((info: any, index: number) => (
                                index % 2 !== 0 && (
                                    <Space size={8} direction="vertical">
                                        <span className="bold cl--secondary-purple paragraph--3">
                                            {info.label}
                                        </span>
                                        <span className="medium cl--primary-dark-blue paragraph--3">
                                            {info.value || ts('NOT_AVAILABLE')}
                                        </span>
                                    </Space>
                                )
                            ))
                        }
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

export default DriverCard;
