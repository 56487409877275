export const MEASURE_QUESTIONS = [
    {
        name: 'deliveriesNumber',
        label: 'DELIVERIES_NUMBER',
        help: 'DELIVERIES_NUMBER_HELP',
        placeholder: 'DELIVERIES_NUMBER_PLACEHOLDER',
        type: 0,
        inputType: 'select',
        options: [
            { value: '1_5', label: 'DELIVERIES_NUMBER_1_5_LABEL' },
            { value: '5_10', label: 'DELIVERIES_NUMBER_5_10_LABEL' },
            { value: 'more_10', label: 'DELIVERIES_NUMBER_MORE_LABEL' },
        ],
    },
    {
        name: 'makeSendAndCollect',
        label: 'SEND_AND_COLLECT',
        help: 'SEND_AND_COLLECT_HELP',
        placeholder: 'SELECT_OPTION',
        type: 0,
        inputType: 'select',
        options: [
            { value: 'yes', label: 'YES' },
            { value: 'no', label: 'NO' },
            { value: 'dont_know', label: 'DONT_KNOW' },
        ],
    },
    {
        name: 'coverageDistance',
        label: 'MAXIMUM_DELIVERY_DISTANCE',
        help: 'MAXIMUM_DELIVERY_DISTANCE_HELP',
        placeholder: 'MAXIMUM_DELIVERY_DISTANCE_PLACEHOLDER',
        type: 1,
        inputType: 'number',
        value: 0,
    },
    {
        name: 'baseFare',
        label: 'AVERAGE_RATE',
        help: 'AVERAGE_RATE_HELP',
        placeholder: 'AVERAGE_RATE_PLACEHOLDER',
        type: 1,
        inputType: 'number',
        value: '',
    },
];
