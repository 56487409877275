import { Divider, Space } from 'antd';
import moment from 'moment';
import cs from 'classnames';

import { useTranslation } from 'react-i18next';
import { StatusUpdate } from '../../types/booking';

import styles from './styles.module.scss';

interface StatusBookingProps {
  statusUpdates: StatusUpdate[];
}

const Status = ({ statusUpdates }: StatusBookingProps) => {
    const { t: ts } = useTranslation('bookings', { keyPrefix: 'STATUS' });
    const { t: td } = useTranslation('bookings', { keyPrefix: 'DETAIL' });

    const getFormatDate = (date: Date, type: string) => {
        if (type === 'time') {
            return moment(date).format('HH:mm');
        }

        return moment(date).format('DD/MM/YYYY');
    };

    return (
        <div className={cs('fullHeight', styles.status)}>
            <span className="bold left cl--primary-dark-blue-3 paragraph--2">
                { td('TIMELINE_BOOKING') }
            </span>
            <div className={styles.containerSteps}>
                {
                    statusUpdates?.map((status: any) => (
                        <div
                            key={status.timeStamp.toString()}
                            className={cs(
                                'flex-row center-row-center',
                                styles.step,
                                styles[status.newStatusText],
                            )}
                        >
                            <Space direction="vertical" size={6}>
                                <span className="medium paragraph--3 left">
                                    { getFormatDate(status.timeStamp, 'date') }
                                </span>
                                <span className="medium paragraph--3 left">
                                    { getFormatDate(status.timeStamp, 'time') }
                                </span>
                            </Space>
                            <Divider type="vertical" className="divider" />
                            <span className="medium paragraph--3 right">
                                { ts(status.newStatusText) }
                            </span>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Status;
