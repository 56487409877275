import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';

import Login from '../screens/Login';
import ResetPassword from '../screens/ResetPassword';
import ConfirmPassword from '../screens/ConfirmPassword';
import Signup from '../screens/Signup';
import Onboarding from '../screens/Onboarding';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import DashboardRouter from './DashboardRouter';

import Layout from '../components/Layout';

export const AppRouter = () => (
    <Router>
        <Routes>
            <Route
                path="/login"
                element={(
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                )}
            />
            <Route
                path="/reset-password"
                element={(
                    <PublicRoute>
                        <ResetPassword />
                    </PublicRoute>
                )}
            />
            <Route
                path="/confirm-password"
                element={(<ConfirmPassword />)}
            />
            <Route
                path="/signup"
                element={(
                    <PublicRoute>
                        <Signup />
                    </PublicRoute>
                )}
            />
            <Route
                path="/onboarding"
                element={(
                    <Navigate to="/onboarding/create-account" />
                )}
            />
            <Route
                path="/onboarding/*"
                element={(
                    <PrivateRoute>
                        <Onboarding />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/*"
                element={(
                    <PrivateRoute>
                        <Layout>
                            <DashboardRouter />
                        </Layout>
                    </PrivateRoute>
                )}
            />
        </Routes>
    </Router>
);

export default AppRouter;
