/* eslint-disable object-curly-newline */
const countryCodes = [
    { flag: 'https://picker-flags.s3.amazonaws.com/af.png', name: 'Afghanistan', callingCodes: ['93'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ax.png', name: 'Åland Islands', callingCodes: ['358'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/al.png', name: 'Albania', callingCodes: ['355'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/dz.png', name: 'Algeria', callingCodes: ['213'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/as.png', name: 'American Samoa', callingCodes: ['1684'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/an.png', name: 'Andorra', callingCodes: ['376'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ao.png', name: 'Angola', callingCodes: ['244'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ai.png', name: 'Anguilla', callingCodes: ['1264'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/aq.png', name: 'Antarctica', callingCodes: ['672'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ag.png', name: 'Antigua and Barbuda', callingCodes: ['1268'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ar.png', name: 'Argentina', callingCodes: ['54'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/am.png', name: 'Armenia', callingCodes: ['374'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/aw.png', name: 'Aruba', callingCodes: ['297'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/au.png', name: 'Australia', callingCodes: ['61'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/at.png', name: 'Austria', callingCodes: ['43'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/az.png', name: 'Azerbaijan', callingCodes: ['994'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bs.png', name: 'Bahamas', callingCodes: ['1242'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bh.png', name: 'Bahrain', callingCodes: ['973'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bg.png', name: 'Bangladesh', callingCodes: ['880'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/br.png', name: 'Barbados', callingCodes: ['1246'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/by.png', name: 'Belarus', callingCodes: ['375'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/be.png', name: 'Belgium', callingCodes: ['32'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bz.png', name: 'Belize', callingCodes: ['501'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bj.png', name: 'Benin', callingCodes: ['229'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bm.png', name: 'Bermuda', callingCodes: ['1441'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bt.png', name: 'Bhutan', callingCodes: ['975'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bo.png', name: 'Bolivia (Plurinational State of)', callingCodes: ['591'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bq.png', name: 'Bonaire, Sint Eustatius and Saba', callingCodes: ['5997'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bi.png', name: 'Bosnia and Herzegovina', callingCodes: ['387'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bw.png', name: 'Botswana', callingCodes: ['267'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bv.png', name: 'Bouvet Island', callingCodes: [''] },
    { flag: 'https://picker-flags.s3.amazonaws.com/br.png', name: 'Brazil', callingCodes: ['55'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/io.png', name: 'British Indian Ocean Territory', callingCodes: ['246'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/um.png', name: 'United States Minor Outlying Islands', callingCodes: [''] },
    { flag: 'https://picker-flags.s3.amazonaws.com/vg.png', name: 'Virgin Islands (British)', callingCodes: ['1284'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/vi.png', name: 'Virgin Islands (U.S.)', callingCodes: ['1 340'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/br.png', name: 'Brunei Darussalam', callingCodes: ['673'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bg.png', name: 'Bulgaria', callingCodes: ['359'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bf.png', name: 'Burkina Faso', callingCodes: ['226'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bd.png', name: 'Burundi', callingCodes: ['257'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/kh.png', name: 'Cambodia', callingCodes: ['855'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cm.png', name: 'Cameroon', callingCodes: ['237'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ca.png', name: 'Canada', callingCodes: ['1'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cv.png', name: 'Cabo Verde', callingCodes: ['238'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cy.png', name: 'Cayman Islands', callingCodes: ['1345'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ca.png', name: 'Central African Republic', callingCodes: ['236'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tc.png', name: 'Chad', callingCodes: ['235'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cl.png', name: 'Chile', callingCodes: ['56'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cn.png', name: 'China', callingCodes: ['86'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cx.png', name: 'Christmas Island', callingCodes: ['61'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cc.png', name: 'Cocos (Keeling) Islands', callingCodes: ['61'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/co.png', name: 'Colombia', callingCodes: ['57'], countryCodeName: 'CO' },
    { flag: 'https://picker-flags.s3.amazonaws.com/km.png', name: 'Comoros', callingCodes: ['269'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cg.png', name: 'Congo', callingCodes: ['242'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cd.png', name: 'Congo (Democratic Republic of the)', callingCodes: ['243'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ck.png', name: 'Cook Islands', callingCodes: ['682'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cr.png', name: 'Costa Rica', callingCodes: ['506'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/hr.png', name: 'Croatia', callingCodes: ['385'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cu.png', name: 'Cuba', callingCodes: ['53'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cw.png', name: 'Curaçao', callingCodes: ['599'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cy.png', name: 'Cyprus', callingCodes: ['357'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/cz.png', name: 'Czech Republic', callingCodes: ['420'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/dk.png', name: 'Denmark', callingCodes: ['45'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/dj.png', name: 'Djibouti', callingCodes: ['253'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/dm.png', name: 'Dominica', callingCodes: ['1767'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/do.png', name: 'Dominican Republic', callingCodes: ['1809'], countryCodeName: 'DOM' },
    { flag: 'https://picker-flags.s3.amazonaws.com/do.png', name: 'Dominican Republic', callingCodes: ['1829'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/do.png', name: 'Dominican Republic', callingCodes: ['1849'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ec.png', name: 'Ecuador', callingCodes: ['593'], countryCodeName: 'EC' },
    { flag: 'https://picker-flags.s3.amazonaws.com/eg.png', name: 'Egypt', callingCodes: ['20'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sl.png', name: 'El Salvador', callingCodes: ['503'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gn.png', name: 'Equatorial Guinea', callingCodes: ['240'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/er.png', name: 'Eritrea', callingCodes: ['291'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ee.png', name: 'Estonia', callingCodes: ['372'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/et.png', name: 'Ethiopia', callingCodes: ['251'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/fk.png', name: 'Falkland Islands (Malvinas)', callingCodes: ['500'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/fo.png', name: 'Faroe Islands', callingCodes: ['298'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/fj.png', name: 'Fiji', callingCodes: ['679'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/fi.png', name: 'Finland', callingCodes: ['358'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/fr.png', name: 'France', callingCodes: ['33'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gu.png', name: 'French Guiana', callingCodes: ['594'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/py.png', name: 'French Polynesia', callingCodes: ['689'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ga.png', name: 'Gabon', callingCodes: ['241'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gm.png', name: 'Gambia', callingCodes: ['220'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ge.png', name: 'Georgia', callingCodes: ['995'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/de.png', name: 'Germany', callingCodes: ['49'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gh.png', name: 'Ghana', callingCodes: ['233'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gi.png', name: 'Gibraltar', callingCodes: ['350'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gr.png', name: 'Greece', callingCodes: ['30'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gl.png', name: 'Greenland', callingCodes: ['299'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gd.png', name: 'Grenada', callingCodes: ['1473'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gl.png', name: 'Guadeloupe', callingCodes: ['590'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gu.png', name: 'Guam', callingCodes: ['1671'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gt.png', name: 'Guatemala', callingCodes: ['502'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gg.png', name: 'Guernsey', callingCodes: ['44'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gi.png', name: 'Guinea', callingCodes: ['224'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gn.png', name: 'Guinea-Bissau', callingCodes: ['245'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gu.png', name: 'Guyana', callingCodes: ['592'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ht.png', name: 'Haiti', callingCodes: ['509'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/va.png', name: 'Holy See', callingCodes: ['379'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/hn.png', name: 'Honduras', callingCodes: ['504'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/hk.png', name: 'Hong Kong', callingCodes: ['852'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/hu.png', name: 'Hungary', callingCodes: ['36'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/is.png', name: 'Iceland', callingCodes: ['354'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/in.png', name: 'India', callingCodes: ['91'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/id.png', name: 'Indonesia', callingCodes: ['62'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ci.png', name: "Côte d'Ivoire", callingCodes: ['225'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ir.png', name: 'Iran (Islamic Republic of)', callingCodes: ['98'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/iq.png', name: 'Iraq', callingCodes: ['964'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ie.png', name: 'Ireland', callingCodes: ['353'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/im.png', name: 'Isle of Man', callingCodes: ['44'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/is.png', name: 'Israel', callingCodes: ['972'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/it.png', name: 'Italy', callingCodes: ['39'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/jm.png', name: 'Jamaica', callingCodes: ['1876'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/jp.png', name: 'Japan', callingCodes: ['81'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/je.png', name: 'Jersey', callingCodes: ['44'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/jo.png', name: 'Jordan', callingCodes: ['962'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/kz.png', name: 'Kazakhstan', callingCodes: ['76', '77'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ke.png', name: 'Kenya', callingCodes: ['254'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ki.png', name: 'Kiribati', callingCodes: ['686'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/kw.png', name: 'Kuwait', callingCodes: ['965'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/kg.png', name: 'Kyrgyzstan', callingCodes: ['996'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/la.png', name: "Lao People's Democratic Republic", callingCodes: ['856'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/lv.png', name: 'Latvia', callingCodes: ['371'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/lb.png', name: 'Lebanon', callingCodes: ['961'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ls.png', name: 'Lesotho', callingCodes: ['266'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/lr.png', name: 'Liberia', callingCodes: ['231'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ly.png', name: 'Libya', callingCodes: ['218'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/li.png', name: 'Liechtenstein', callingCodes: ['423'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/lt.png', name: 'Lithuania', callingCodes: ['370'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/lu.png', name: 'Luxembourg', callingCodes: ['352'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mo.png', name: 'Macao', callingCodes: ['853'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mk.png', name: 'Macedonia (the former Yugoslav Republic of)', callingCodes: ['389'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mg.png', name: 'Madagascar', callingCodes: ['261'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mw.png', name: 'Malawi', callingCodes: ['265'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/my.png', name: 'Malaysia', callingCodes: ['60'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mv.png', name: 'Maldives', callingCodes: ['960'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ml.png', name: 'Mali', callingCodes: ['223'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mt.png', name: 'Malta', callingCodes: ['356'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mh.png', name: 'Marshall Islands', callingCodes: ['692'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mq.png', name: 'Martinique', callingCodes: ['596'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mr.png', name: 'Mauritania', callingCodes: ['222'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mu.png', name: 'Mauritius', callingCodes: ['230'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/my.png', name: 'Mayotte', callingCodes: ['262'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mx.png', name: 'Mexico', callingCodes: ['52'], countryCodeName: 'MX' },
    { flag: 'https://picker-flags.s3.amazonaws.com/fm.png', name: 'Micronesia (Federated States of)', callingCodes: ['691'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/md.png', name: 'Moldova (Republic of)', callingCodes: ['373'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mc.png', name: 'Monaco', callingCodes: ['377'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mn.png', name: 'Mongolia', callingCodes: ['976'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/me.png', name: 'Montenegro', callingCodes: ['382'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ms.png', name: 'Montserrat', callingCodes: ['1664'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ma.png', name: 'Morocco', callingCodes: ['212'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mo.png', name: 'Mozambique', callingCodes: ['258'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mm.png', name: 'Myanmar', callingCodes: ['95'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/na.png', name: 'Namibia', callingCodes: ['264'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/nr.png', name: 'Nauru', callingCodes: ['674'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/np.png', name: 'Nepal', callingCodes: ['977'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/nl.png', name: 'Netherlands', callingCodes: ['31'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/nc.png', name: 'New Caledonia', callingCodes: ['687'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/nz.png', name: 'New Zealand', callingCodes: ['64'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ni.png', name: 'Nicaragua', callingCodes: ['505'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ne.png', name: 'Niger', callingCodes: ['227'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ng.png', name: 'Nigeria', callingCodes: ['234'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ni.png', name: 'Niue', callingCodes: ['683'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/nf.png', name: 'Norfolk Island', callingCodes: ['672'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pr.png', name: "Korea (Democratic People's Republic of)", callingCodes: ['850'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mp.png', name: 'Northern Mariana Islands', callingCodes: ['1670'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/no.png', name: 'Norway', callingCodes: ['47'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/om.png', name: 'Oman', callingCodes: ['968'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pa.png', name: 'Pakistan', callingCodes: ['92'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pl.png', name: 'Palau', callingCodes: ['680'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ps.png', name: 'Palestine, State of', callingCodes: ['970'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pa.png', name: 'Panama', callingCodes: ['507'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pg.png', name: 'Papua New Guinea', callingCodes: ['675'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/py.png', name: 'Paraguay', callingCodes: ['595'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pe.png', name: 'Peru', callingCodes: ['51'], countryCodeName: 'PE' },
    { flag: 'https://picker-flags.s3.amazonaws.com/ph.png', name: 'Philippines', callingCodes: ['63'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pn.png', name: 'Pitcairn', callingCodes: ['64'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pl.png', name: 'Poland', callingCodes: ['48'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pt.png', name: 'Portugal', callingCodes: ['351'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pr.png', name: 'Puerto Rico', callingCodes: ['1787', '1939'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/qa.png', name: 'Qatar', callingCodes: ['974'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/xk.png', name: 'Republic of Kosovo', callingCodes: ['383'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/re.png', name: 'Réunion', callingCodes: ['262'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ro.png', name: 'Romania', callingCodes: ['40'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ru.png', name: 'Russian Federation', callingCodes: ['7'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/rw.png', name: 'Rwanda', callingCodes: ['250'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/bl.png', name: 'Saint Barthélemy', callingCodes: ['590'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sh.png', name: 'Saint Helena, Ascension and Tristan da Cunha', callingCodes: ['290'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/kn.png', name: 'Saint Kitts and Nevis', callingCodes: ['1869'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/lc.png', name: 'Saint Lucia', callingCodes: ['1758'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/mf.png', name: 'Saint Martin (French part)', callingCodes: ['590'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/pm.png', name: 'Saint Pierre and Miquelon', callingCodes: ['508'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/vc.png', name: 'Saint Vincent and the Grenadines', callingCodes: ['1784'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ws.png', name: 'Samoa', callingCodes: ['685'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sm.png', name: 'San Marino', callingCodes: ['378'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/st.png', name: 'Sao Tome and Principe', callingCodes: ['239'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sa.png', name: 'Saudi Arabia', callingCodes: ['966'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/se.png', name: 'Senegal', callingCodes: ['221'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sr.png', name: 'Serbia', callingCodes: ['381'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sy.png', name: 'Seychelles', callingCodes: ['248'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sl.png', name: 'Sierra Leone', callingCodes: ['232'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sg.png', name: 'Singapore', callingCodes: ['65'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sx.png', name: 'Sint Maarten (Dutch part)', callingCodes: ['1721'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sk.png', name: 'Slovakia', callingCodes: ['421'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/si.png', name: 'Slovenia', callingCodes: ['386'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/si.png', name: 'Slovenia Islands', callingCodes: ['677'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/so.png', name: 'Somalia', callingCodes: ['252'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/za.png', name: 'South Africa', callingCodes: ['27'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sg.png', name: 'South Georgia and the South Sandwich Islands', callingCodes: ['500'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/kp.png', name: 'Korea (Republic of)', callingCodes: ['82'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ss.png', name: 'South Sudan', callingCodes: ['211'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/es.png', name: 'Spain', callingCodes: ['34'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/lk.png', name: 'Sri Lanka', callingCodes: ['94'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sd.png', name: 'Sudan', callingCodes: ['249'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sr.png', name: 'Suriname', callingCodes: ['597'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sj.png', name: 'Svalbard and Jan Mayen', callingCodes: ['4779'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sz.png', name: 'Swaziland', callingCodes: ['268'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/se.png', name: 'Sweden', callingCodes: ['46'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ch.png', name: 'Switzerland', callingCodes: ['41'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/sy.png', name: 'Syrian Arab Republic', callingCodes: ['963'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tw.png', name: 'Taiwan', callingCodes: ['886'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tj.png', name: 'Tajikistan', callingCodes: ['992'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tz.png', name: 'Tanzania, United Republic of', callingCodes: ['255'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/th.png', name: 'Thailand', callingCodes: ['66'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tl.png', name: 'Timor-Leste', callingCodes: ['670'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tg.png', name: 'Togo', callingCodes: ['228'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tk.png', name: 'Tokelau', callingCodes: ['690'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/to.png', name: 'Tonga', callingCodes: ['676'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tt.png', name: 'Trinidad and Tobago', callingCodes: ['1868'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tn.png', name: 'Tunisia', callingCodes: ['216'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tr.png', name: 'Turkey', callingCodes: ['90'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tm.png', name: 'Turkmenistan', callingCodes: ['993'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tc.png', name: 'Turks and Caicos Islands', callingCodes: ['1649'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/tv.png', name: 'Tuvalu', callingCodes: ['688'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ug.png', name: 'Uganda', callingCodes: ['256'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ua.png', name: 'Ukraine', callingCodes: ['380'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ae.png', name: 'United Arab Emirates', callingCodes: ['971'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/gb.png', name: 'United Kingdom of Great Britain and Northern Ireland', callingCodes: ['44'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/us.png', name: 'United States of America', callingCodes: ['1'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/uy.png', name: 'Uruguay', callingCodes: ['598'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/uz.png', name: 'Uzbekistan', callingCodes: ['998'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/vu.png', name: 'Vanuatu', callingCodes: ['678'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ve.png', name: 'Venezuela (Bolivarian Republic of)', callingCodes: ['58'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/vn.png', name: 'Viet Nam', callingCodes: ['84'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/wf.png', name: 'Wallis and Futuna', callingCodes: ['681'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/eh.png', name: 'Western Sahara', callingCodes: ['212'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/ye.png', name: 'Yemen', callingCodes: ['967'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/zm.png', name: 'Zambia', callingCodes: ['260'] },
    { flag: 'https://picker-flags.s3.amazonaws.com/zw.png', name: 'Zimbabwe', callingCodes: ['263'] },
];

export default countryCodes;
