import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';

interface HeaderSectionProps{
  title: string;
  icon: any;
  showBack?: boolean;
  defaultBack?: string;
}

const HeaderSection = ({
    title, icon, showBack, defaultBack,
}: HeaderSectionProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleBack = () => {
        const hasBack = location.key !== 'default';
        const route = hasBack ? -1 : defaultBack;
        navigate(route as any);
    };

    return (
        <div className="flex-row start-row-center full-width header">
            {showBack && (
                <div className="flex-row center-row-center iconBack" onClick={handleBack}>
                    <AiOutlineArrowLeft size={24} className="cl--secondary-purple" />
                </div>
            )}
            {icon}
            <span className="bold title--header-2 cl--secondary-purple">{title}</span>
        </div>
    );
};

export default HeaderSection;
