import {
    Row, Col, Space, Alert,
} from 'antd';
import { useEffect, useState } from 'react';
import { BiPackage } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HeaderSection from '../components/HeaderSection';
import {
    Status, Info, PriceBreakUp, DistanceBreakUp,
} from '../components/BookingDetail';

import { getBookingDetail } from '../api/bookings';
import { Booking } from '../types/booking';

const BookingDetail = () => {
    const { t: td } = useTranslation('bookings', { keyPrefix: 'DETAIL' });

    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);
    const [bookingData, setBookingData] = useState<Booking>({} as Booking);
    const params = useParams();

    const getBooking = () => {
        const bookingID = params.id;
        setLoading(true);
        getBookingDetail(bookingID)
            .then(({ data }: any) => {
                setBookingData(data);
            })
            .catch((error: any) => console.log(error.message))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!params.id) return;
        getBooking();
    }, []);

    return (
        <div className="bg--primary-white fullHeight fullWidth contentLayouts">
            <Space direction="vertical" size={32} className="fullWidth">
                <HeaderSection
                    title={td('DETAIL_BOOKING')}
                    icon={<BiPackage size={40} className="iconHeader" />}
                />
                <Row
                    align="top"
                    justify="space-around"
                    className="fullWidth fullHeight noMargin"
                    gutter={[24, 48]}
                >
                    <Col xs={24} lg={6} className="fullHeight" style={{ paddingLeft: 0 }}>
                        {
                            bookingData.statusUpdates?.length > 0
                                ? <Status statusUpdates={bookingData.statusUpdates} />
                                : <Alert message={td('NO_TRACKING')} type="info" showIcon />
                        }
                    </Col>
                    <Col xs={24} lg={18} className="fullHeight" style={{ paddingRight: 0 }}>
                        <Space direction="vertical" size={40}>
                            <Info bookingData={bookingData} />
                            <DistanceBreakUp distanceBreakUpData={bookingData.distanceBreakUp} />
                            <PriceBreakUp
                                priceBreakUpData={bookingData.priceBreakUp}
                                paymentMethod={bookingData.paymentMethod}
                                sumOrderAndDelivery={bookingData.sumOrderAndDelivery}
                                ServiceAmount={bookingData.ServiceAmount}
                                deliveryFee={bookingData.deliveryFee}
                                currency={bookingData.currency}
                            />
                        </Space>
                    </Col>
                </Row>
            </Space>
        </div>
    );
};

export default BookingDetail;
