const types = {
    login: '[Auth] Login',
    logout: '[Auth] Logout',
    signup: '[Auth] Signup',
    socialSignup: '[Auth] Social Signup',
    registerMarketingData: '[Auth] Register Marketing Data',
    validateEmail: '[Auth] Validate Email',

    uiSetInfo: '[UI] Set Info',
    uiRemoveInfo: '[UI] Remove Info',
    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    workspaceLoad: '[WS] Load Workspaces',
    workspaceSetActive: '[WS] Set active Workspace',
    workspaceStartLoading: '[WS] Start loading Workspace',
    workspaceFinishLoading: '[WS] Finish loading Workspace',
    workspaceClean: '[WS] Clean workspace',

};

export default types;
