import cs from 'classnames';
import { Space, Button, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from '../../styles/screens/Onboarding.module.scss';

import IMAGES from '../../const/images';

const Completed = () => {
    const { t: tc } = useTranslation('onboarding', { keyPrefix: 'COMPLETED' });

    return (
        <div className={styles.innerSection}>
            <Space direction="vertical" size={24}>
                <Space direction="vertical" size={0}>
                    <div className={styles.header}>
                        <span className="title--header-2 cl--secondary-purple">
                            { tc('ACCOUNT_SUCCESS') }
                        </span>
                    </div>
                    <span className="paragraph--2 center">
                        { tc('US_DELIVERY_PARTNER') }
                    </span>
                </Space>
                <Avatar
                    src={IMAGES.onboarding.completed}
                    size={250}
                    shape="square"
                    className={styles.avatar}
                />
                <Link to="/">
                    <Button
                        type="primary"
                        className={cs(styles.button, 'lg')}
                    >
                        { tc('BEGIN') }
                    </Button>
                </Link>
            </Space>
        </div>
    );
};

export default Completed;
