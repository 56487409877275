import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from './store';

/* Plugins */
import './plugins/antd';
import './plugins/moment';
import './plugins/axios';

import { AppRouter } from './routers/AppRouter';
import LoadingPage from './components/LoadingPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
    <Provider store={store}>
        <Suspense fallback={<LoadingPage />}>
            <AppRouter />
        </Suspense>
    </Provider>,
);
