import types from '../types';

export const setError = (err: any) => ({
    type: types.uiSetError,
    payload: err,
});

export const removeError = () => ({
    type: types.uiRemoveError,
});

export const setInfo = (info: any) => ({
    type: types.uiSetInfo,
    payload: info,
});

export const removeInfo = () => ({
    type: types.uiRemoveInfo,
});

export const startLoading = () => ({
    type: types.uiStartLoading,
});

export const finishLoading = () => ({
    type: types.uiFinishLoading,
});
