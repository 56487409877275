import cs from 'classnames';
import {
    Form, Space, Button, Input, Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { startForgotPassword } from '../../actions/auth';
import { RootState } from '../../reducers/root';

import styles from './styles.module.scss';

const ResetForm = () => {
    const { t } = useTranslation('login');
    const { ui: { loading, msgError, msgInfo } } = useSelector((state: RootState) => state);

    const dispatch: any = useDispatch();

    const handleSubmit = ({ email }: { email: string}) => {
        const role = 'deliveryPartner';
        const payload = {
            email, role,
        };
        dispatch(startForgotPassword(payload));
    };

    return (
        <Form
            onFinish={handleSubmit}
            layout="vertical"
        >
            <Space size={32} direction="vertical">
                <Form.Item
                    name="email"
                    label={t('EMAIL')}
                    className={styles.formInput}
                    rules={[
                        { type: 'email', message: t('INVALID_EMAIL') },
                        { required: true, message: t('REQUIRED') },
                    ]}
                >
                    <Input
                        size="large"
                        placeholder={t('EMAIL')}
                        disabled={loading}
                    />
                </Form.Item>

                {
                    (msgError) && (<Alert message={msgError} type="error" />)
                }
                {
                    (msgInfo) && (<Alert message={msgInfo} type="success" />)
                }

                <Button
                    type="primary"
                    htmlType="submit"
                    className={cs(styles.button, 'fullWidth')}
                    loading={loading}
                >
                    {t('RESET_PASSWORD')}
                </Button>
            </Space>
        </Form>
    );
};

export default ResetForm;
