/* eslint-disable no-unused-vars */
export enum PaymentMethod {
  CARD = 'CARD',
  BILLING_CARD = 'BILLING_CARD',
  BILLING_TRANSFER = 'BILLING_TRANSFER',
  CASH = 'CASH'
}

export enum PaymentGateway {
  PAYMENTEZ = 'PAYMENTEZ'
}

export enum DebitFrecuency {
  MONTHLY = 'MONTHLY'
}

export enum Currency {
  MXN = 'MXN',
  USD = 'USD',
}
