export const PMTZ_ENV = process.env.REACT_APP_PMTZ_ENV;
export const PMTZ_CODE = process.env.REACT_APP_PMTZ_CODE;
export const PMTZ_KEY = process.env.REACT_APP_PMTZ_KEY;
export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
export const ZENDESK_API_KEY = process.env.REACT_APP_ZENDESK_API_KEY || '';

export const DRIVER_LOGO_PURPLE = process.env.REACT_APP_DRIVER_LOGO_PURPLE;
export const DRIVER_LOGO_BLUE = process.env.REACT_APP_DRIVER_LOGO_BLUE;
export const DRIVER_LOGO_YELLOW = process.env.REACT_APP_DRIVER_LOGO_YELLOW;
