import {
    Space, Avatar, notification, Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AiFillIdcard, AiOutlineCar } from 'react-icons/ai';

import { RootState } from '../../reducers/root';
import { getDriverByID } from '../../api/drivers';

import HeaderSection from '../HeaderSection';
import IMAGES from '../../const/images';
import DriverCard from '../DriverCard';

const DriverDetail = () => {
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });

    const params = useParams();
    const { active } = useSelector((state: RootState) => state.workspace);

    const [loading, setLoading] = useState(false);
    const [driver, setDriver] = useState<any>();

    const getDriver = () => {
        setLoading(true);
        const queryParams = {
            driverID: params?.id,
            smallFleetID: active?._id,
        };
        getDriverByID(queryParams)
            .then(({ body }: any) => {
                setDriver(body);
            })
            .catch((error: any) => {
                const description = error.response.data.message;
                notification.error({
                    message: td('DRIVER_NOT_OBTAINED'),
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!params.id) return;
        getDriver();
    }, [active?._id, params.id]);

    return (
        <div className="fullWidth fullHeight home flex-row center-row-start">
            <div className="contentLayouts fullWidth fullHeight bg--primary-white">
                <Space direction="vertical" size={64}>
                    <HeaderSection
                        title={td('DRIVER_DETAIL')}
                        icon={
                            <Avatar size={40} className="iconHeader" shape="square" src={IMAGES.icons.driver} />
                        }
                        showBack
                        defaultBack="/drivers"
                    />
                    {
                        loading
                            ? <Spin className="center fullWidth" />
                            : (
                                <div style={{ padding: '0 32px' }}>
                                    <Space className="fullWidth" direction="vertical" size={64}>
                                        <DriverCard
                                            title={td('DRIVER_INFORMATION')}
                                            icon={<AiFillIdcard color="#6C8EEF" size={24} />}
                                            type="DRIVER"
                                            driver={{ ...driver, ...driver?.preference }}
                                        />
                                        <DriverCard
                                            title={td('VEHICLE_INFORMATION')}
                                            icon={<AiOutlineCar color="#6C8EEF" size={24} />}
                                            type="VEHICLE"
                                            driver={{ ...driver?.driverID }}
                                        />
                                    </Space>
                                </div>
                            )
                    }
                </Space>
            </div>
        </div>
    );
};

export default DriverDetail;
