import axios from 'axios';
import { BASE_DASHBOARD_URL } from './index';

export const getDriversBySmallFleet = (queryParams: any) => {
    const route = '/delivery-partner/getDriversBySmallFleet';
    const url = new URL(`${BASE_DASHBOARD_URL}${route}`);
    url.searchParams.set('limit', queryParams.limit || 10);
    url.searchParams.set('page', queryParams.page || 1);
    url.searchParams.set('smallFleetID', queryParams.smallFleetID);
    url.toString();
    return axios.get(url.href);
};

export const addDriver = (payload: any) => {
    const route = '/delivery-partner/addDriver';
    const url = `${BASE_DASHBOARD_URL}${route}`;
    return axios.post(url, payload);
};

export const removeDriver = (payload: any) => {
    const route = '/delivery-partner/removeDriver';
    const url = `${BASE_DASHBOARD_URL}${route}`;
    return axios.post(url, payload);
};

export const resendInvitation = (userID: any) => {
    const route = `/delivery-partner/resendDriverInvitation?userID=${userID}`;
    const url = `${BASE_DASHBOARD_URL}${route}`;
    return axios.post(url);
};

export const getDriverByID = (queryParams: any) => {
    const route = '/delivery-partner/getDriverFromSmallFleet';
    const url = new URL(`${BASE_DASHBOARD_URL}${route}`);
    url.searchParams.set('smallFleetID', queryParams.smallFleetID);
    url.searchParams.set('userID', queryParams.driverID);
    url.toString();
    return axios.get(url.href);
};

export const updateDriver = (payload: any) => {
    const route = '/delivery-partner/editDriver';
    const url = `${BASE_DASHBOARD_URL}${route}`;
    return axios.post(url, payload);
};
