import { Space } from 'antd';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import useBookings from '../../hooks/useBookings';
import TableBookings from '../TableBookings';
import { TableBookingsProps } from '../TableBookings/TableBookings';
import { Filters } from '.';

const Bookings = () => {
    const active = useSelector((state: any) => state.workspace.active);

    const LIMIT = 10;

    const [search, setSearch] = useState<string>('');
    const [startDate] = useState<string>('');
    const [endDate] = useState<string>('');
    const [numericId, setNumericId] = useState<string>('');

    const [loading, bookings, total, refreshData, fetchBookings, paginator, props]: any = useBookings({
        typeBookings: 'onlyActives',
        search,
        args: { limit: LIMIT },
        startDate,
        endDate,
    });

    const fetchDebounced = useCallback<any>(debounce(refreshData, 1000), []);

    const handleSearch = (e: any) => {
        const { value } = e.target;
        setSearch(value);
        fetchDebounced(value, false);
    };

    const handleNumericId = (e: any) => {
        const { value } = e.target;
        setNumericId(value);
        fetchDebounced(value, true);
    };

    useEffect(() => {
        if (!active) return;
        fetchBookings();
    }, [active, paginator]);

    return (
        <Space direction="vertical" size={20} style={{ width: '100%' }}>
            <Filters
                loading={loading}
                search={search}
                handleSearch={handleSearch}
                numericId={numericId}
                handleNumericId={handleNumericId}
                refreshData={refreshData}
            />
            <TableBookings
                loading={loading}
                total={total}
                data={bookings}
                paginator={paginator}
                refreshData={refreshData}
                showSort
                {...props as Partial<TableBookingsProps>}
            />
        </Space>
    );
};

export default Bookings;
