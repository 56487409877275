import cs from 'classnames';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { BsInfoCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import IMAGES from '../const/images';

import Header from '../components/Onboarding/Header';
import { ResetForm } from '../components/ResetPassword';

import styles from '../styles/screens/Onboarding.module.scss';

const ResetPassword = () => {
    const { t } = useTranslation('login');

    const createAccountLink = () => (
        <Link to="/signup" className={styles.createAccountLink}>
            {t('CREATE_AN_ACCOUNT')}
        </Link>
    );

    return (
        <div
            style={{ backgroundImage: `url(${IMAGES.login.spirals})` }}
            className={cs(styles.pageLayout, styles.reset, 'flex-column center-column-center')}
        >
            <Space
                direction="vertical"
                size={40}
                style={{
                    maxWidth: 570,
                    margin: '0 auto',
                }}
            >
                <Header />
                <span className="cl--secondary-purple title--header-1 center">
                    { t('RESET_PASSWORD') }
                </span>
                <span className="cl--secondary-purple paragraph--2 center medium">
                    { t('RESET_PASSWORD_HELP')}
                </span>
                <Space direction="vertical" size={72}>
                    <ResetForm />
                    <div className={cs(styles.forgotPassword, 'flex-row center-row-center')}>
                        <BsInfoCircle />
                        {t('DO_NOT_HAVE_AN_ACCOUNT')}
                        { createAccountLink() }
                    </div>
                </Space>
            </Space>
        </div>
    );
};

export default ResetPassword;
