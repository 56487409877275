import { Layout as LayoutAntd, Button } from 'antd';
import { BsCaretRightFill, BsCaretLeftFill } from 'react-icons/bs';
import { useState } from 'react';

import cs from 'classnames';
import styles from './styles.module.scss';

import SideMenu from '../SideMenu';
import Header from './Header';

const { Content, Sider } = LayoutAntd;

const Layout = ({ children }: { children: any}) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const toggleCollapsed = () => setIsCollapsed(!isCollapsed);

    return (
        <LayoutAntd hasSider className={cs('bg--neutral-neutral-3', styles.mainLayout)}>
            <Button
                className={cs('flex-row center-row-center', styles.toggle, { [styles.collapsed]: isCollapsed })}
                type="primary"
                shape="circle"
                icon={isCollapsed ? <BsCaretRightFill size={16} className="cl--primary-white" /> : <BsCaretLeftFill size={16} className="cl--primary-white" />}
                onClick={() => toggleCollapsed()}
            />
            <Sider
                theme="light"
                className={cs(styles.sider, 'fullHeight')}
                collapsed={isCollapsed}
                width={250}
                collapsedWidth="72"
            >
                <SideMenu isCollapsed={isCollapsed} />
            </Sider>
            <LayoutAntd className={cs('bg--neutral-neutral-3', styles.contentLayout, { [styles.collapsed]: isCollapsed })}>
                <Content className={styles.content}>
                    <Header />
                    {children}
                </Content>
            </LayoutAntd>
        </LayoutAntd>
    );
};

export default Layout;
