/* eslint-disable import/no-cycle */
import types from '../types';
import { User } from '../types/user';
import { Action } from './root';

export type UserState = User & {
  emailExist: null | boolean;
  mustAnswerQuestion?: boolean;
}

const initialState: UserState = {
    emailExist: null,
} as UserState;

export const authReducer = (state = initialState, action: Action) => {
    switch (action.type) {
    case types.validateEmail:
        return {
            emailExist: action.payload,
        };

    case types.registerMarketingData:
        return {
            ...state,
            mustAnswerQuestion: false,
        };

    case types.signup:
        return {
            ...state,
            ...action.payload,
        };

    case types.login:
        return {
            ...state,
            ...action.payload,
        };

    case types.logout:
        return initialState;

    default:
        return state;
    }
};
