import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
    List, Avatar, Spin, Divider, Space, Button, Modal, notification,
} from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiTrash } from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';

import HeaderSection from '../HeaderSection';

import styles from './styles.module.scss';
import IMAGES from '../../const/images';
import ItemDriver from '../ItemDriver';

import { RootState } from '../../reducers/root';
import { getDriversBySmallFleet, removeDriver, resendInvitation } from '../../api/drivers';
import { Driver } from '../../types/driver';

const { confirm } = Modal;

interface queryParamsProps {
  limit?: number,
  page: number,
  smallFleetID: string,
}

interface DriversListProps {
  showActions?: boolean,
  driverUpdated?: any,
}

const DriversList = ({ showActions, driverUpdated }: DriversListProps) => {
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });
    const { t: ta } = useTranslation('shared', { keyPrefix: 'ACTIONS' });

    const LIMIT = 10;
    const navigate = useNavigate();
    const params = useParams();

    const path = params['*']?.toString().split('/')[1];

    const [drivers, setDrivers] = useState<any>([]);

    const [maxDrivers, setMaxDrivers] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const { active } = useSelector((state: RootState) => state.workspace);

    const getDrivers = () => {
        setLoading(true);
        const PAGE = (Math.ceil(drivers.length / LIMIT) + 1);

        const queryParams: queryParamsProps = {
            page: PAGE,
            limit: LIMIT,
            smallFleetID: active?._id,
        };

        getDriversBySmallFleet(queryParams)
            .then(({ body }: any) => {
                setMaxDrivers(active.drivers.length);
                setDrivers([...drivers, ...body.drivers]);
            })
            .catch((error: any) => console.log(error.message))
            .finally(() => setLoading(false));
    };

    const updateList = (driverData: Driver) => {
        const newDrivers = drivers.filter((driver: Driver) => driver?._id !== driverData?._id);
        setDrivers([]);
        setDrivers(newDrivers);

        if (path === driverData._id) {
            navigate('/drivers');
        }
    };

    const confirmRemoveDriver = (driverData: Driver) => {
        confirm({
            title: td('DELETE_QUESTION_DESCRIPTION', {
                name: driverData.name,
                lastName: driverData.lastName,
            }),
            content: td('DELETE_QUESTION'),
            icon: (
                <span role="img" aria-label="fi-trash">
                    <FiTrash color="#FF426F" size={24} />
                </span>
            ),
            okText: ta('DELETE'),
            okType: 'danger',
            onOk() {
                setLoading(true);
                const payload = {
                    userID: driverData._id,
                    smallFleetID: active?._id,
                };
                removeDriver(payload)
                    .then(() => {
                        notification.success({
                            message: td('DRIVER_REMOVED'),
                        });
                        updateList(driverData);
                    }).catch((error: any) => {
                        const description = error.response.data.message;
                        notification.error({
                            message: td('DRIVER_NO_REMOVED'),
                            description,
                        });
                    }).finally(() => setLoading(false));
            },
            onCancel() {
                console.log('Cancel delete driver');
            },
        });
    };

    const resendDriverInvitation = (driverID: string) => {
        setLoading(true);
        resendInvitation(driverID)
            .then(() => {
                notification.success({
                    message: td('RESEND_INVITATION'),
                    description: td('RESEND_INVITATION_SUCCESS'),
                });
            })
            .catch((error: any) => {
                const description = error.response.data.message;
                notification.error({
                    message: td('SEND_INVITATION_ERROR'),
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!active._id) return;
        getDrivers();
    }, []);

    useEffect(() => {
        if (driverUpdated) {
            const driverToUpdated = drivers.filter((driver: Driver) => driver?._id === path);

            if (driverToUpdated.length > 0) {
                driverToUpdated[0].name = driverUpdated.name;
                driverToUpdated[0].lastName = driverUpdated.lastName;
                driverToUpdated[0].countryCode = driverUpdated.countryCode;
                driverToUpdated[0].mobile = driverUpdated.mobile;
                driverToUpdated[0].fullMobile = `${driverUpdated.countryCode}${driverUpdated.mobile}`;
                driverToUpdated[0].email = driverUpdated.email;
            }

            const newDrivers = drivers.filter((driver: Driver) => driver?._id !== path);
            newDrivers.push(driverToUpdated[0]);
            setDrivers([]);
            setDrivers(newDrivers);
        }
    }, [driverUpdated]);

    return (
        <div className="drivers">
            <Space direction="vertical" size={40}>
                <HeaderSection
                    title={td('MY_DRIVERS')}
                    icon={
                        <Avatar size={40} className="iconHeader" shape="square" src={IMAGES.icons.driver} />
                    }
                />

                {
                    showActions && (
                        <div className={styles.containerActions}>
                            <Space direction="horizontal" size={16}>
                                <Button
                                    className="flex-row center-row-center"
                                    type="primary"
                                    size="large"
                                    icon={<AiOutlinePlus size={24} className="cl--primary-white" />}
                                    onClick={() => navigate('/drivers/newDriver')}
                                />
                            </Space>
                        </div>
                    )
                }

                <div className={styles.containerList} id="scrollableDrivers">
                    <InfiniteScroll
                        dataLength={drivers.length}
                        next={() => getDrivers()}
                        hasMore={drivers.length < maxDrivers}
                        loader={loading && <Spin />}
                        endMessage={(
                            <Divider plain>
                                <span className="cl--neutral-default medium paragraph--3">
                                    { td('NO_MORE_DRIVERS') }
                                </span>
                            </Divider>
                        )}
                        scrollableTarget="scrollableDrivers"
                    >
                        <List
                            dataSource={drivers}
                            renderItem={(driver: Driver) => (
                                <ItemDriver
                                    key={driver._id}
                                    driver={driver}
                                    id={driver._id}
                                    loading={loading}
                                    handleRemoveDriver={(driverData: Driver) => confirmRemoveDriver(driverData)}
                                    resendInvitation={(driverID: string) => resendDriverInvitation(driverID)}
                                />
                            )}
                        />
                    </InfiniteScroll>
                </div>
            </Space>
        </div>
    );
};

export default DriversList;
