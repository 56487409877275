import {
    Form, Alert, Space, Input, Button, Select, Tooltip,
} from 'antd';
import cs from 'classnames';
import { get } from 'lodash';

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BsFillInfoCircleFill } from 'react-icons/bs';

import styles from '@/styles/screens/Onboarding.module.scss';

import { MEASURE_QUESTIONS } from '../../../../const/measureQuestion';
import { getRegionsApi } from '../../../../api/country';
import countryCodes from '../../../../const/country-codes';
import TagRender from '../../../TagRender';

const pattern = /[0-9]+$/i;

interface CountryCodes {
  flag: string;
  name: string;
  callingCodes: string[];
  countryCodeName?: string;
}

interface IAboutYou {
  form: any;
  workspaceData: any;
  // eslint-disable-next-line no-unused-vars
  setWorkspaceData: (values: any) => void;
  handleFormData: () => void;
}

const AboutYou = ({
    form,
    workspaceData,
    setWorkspaceData,
    handleFormData,
}: IAboutYou) => {
    const { t } = useTranslation('onboarding');
    const { t: mq } = useTranslation('onboarding', { keyPrefix: 'MEASURE_QUESTIONS' });
    const { t: ca } = useTranslation('onboarding', { keyPrefix: 'CREATE_ACCOUNT' });
    const { t: ra } = useTranslation('onboarding', { keyPrefix: 'RESPONSE_ACTIONS' });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();
    const [countries, setCountry] = useState<CountryCodes[]>([]);
    const [cities, setCities] = useState<string[]>([]);
    const [countryCodeName, setCountryCodeName] = useState('');

    const onSubmit = async () => {
        await form.validateFields();
        setLoading(true);
        try {
            const formValues = form.getFieldsValue();
            setWorkspaceData({ ...workspaceData, ...formValues, countryCodeName });
            handleFormData();
        } catch (error: any) {
            console.log(error);
            setError(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const selectCities = (value: string) => {
        setCities([]);
        form.setFieldValue('coverageCities', []);
        setLoading(true);
        const currentCoutryCode = countryCodes.find((country) => country.name === value);
        if (currentCoutryCode) {
            setCountryCodeName(currentCoutryCode.countryCodeName || '');
        }

        if (error === ra('COUNTRY_NOT_FOUND')) setError(undefined);
        getRegionsApi(value)
            .then((response: any) => {
                const citiesSorted = response.data.sort();
                setCities(citiesSorted);
            })
            .catch((error: any) => {
                const code = get(error, 'response.status', 500);
                if (code === 404) setError(ra('COUNTRY_NOT_FOUND'));
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        const enabled = countryCodes.filter((country) => country.countryCodeName);
        setCountry(enabled);
        selectCities('Ecuador');
    }, []);

    const lightTooltip = (help: string) => (
        <div className={styles.containerHelp}>
            {mq(help)}
        </div>
    );

    const helpLabel = (label: string, help: string) => (
        <div className="flex-row start-row-center">
            <span className="cl--secondary-purple" style={{ marginRight: 8 }}>
                {mq(label)}
            </span>
            <Tooltip
                placement="right"
                overlay={lightTooltip(help)}
                color="#1A1F36"
                style={{ maxWidth: 320 }}
                overlayClassName={styles.tooltip}
                trigger={['click', 'hover']}
            >
                <BsFillInfoCircleFill />
            </Tooltip>
        </div>
    );

    return (
        <div className={styles.innerSection}>
            <Form form={form} layout="vertical" className={styles.form}>
                <Space direction="vertical" size={24}>
                    <div className={styles.header}>
                        <span className="title--header-2 cl--secondary-purple">
                            {t('ABOUT_YOU')}
                        </span>
                    </div>
                    {error && (
                        <Alert message={error} type="error" />
                    )}
                    {
                        MEASURE_QUESTIONS.map((question: any) => (
                            question.type === 0
                                ? (
                                    <Form.Item
                                        key={question.name}
                                        name={question.name}
                                        label={helpLabel(question.label, question.help)}
                                        className={styles.formInput}
                                        rules={[{ required: true, message: ca('FIELD_REQUIRED') }]}
                                        initialValue={question.options[0].value}
                                    >
                                        <Select
                                            size="large"
                                            className="signup-select"
                                        >
                                            {question.options.map((option: any) => (
                                                <Select.Option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {mq(option.label)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )
                                : (
                                    <Form.Item
                                        key={question.name}
                                        name={question.name}
                                        label={helpLabel(question.label, question.help)}
                                        className={styles.formInput}
                                        rules={[
                                            {
                                                required: true,
                                                message: ca('FIELD_REQUIRED'),
                                            },
                                            {
                                                pattern: new RegExp(pattern),
                                                message: ca('INVALID_FIELD_NUMBER'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            type={question.inputType}
                                            placeholder={mq(question.placeholder)}
                                            onKeyDown={(event) => {
                                                const re = /^[0-9\b]+$/;
                                                if (!re.test(event.key) && event.key !== 'Backspace') {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                )
                        ))
                    }
                    <Form.Item
                        key="country"
                        name="country"
                        label={helpLabel('COUNTRY', 'COUNTRY_HELP')}
                        className={styles.formInput}
                        rules={[{ required: true, message: ca('FIELD_REQUIRED') }]}
                        initialValue="Ecuador"
                    >
                        <Select
                            onSelect={selectCities}
                            size="large"
                            className="signup-select"
                            showSearch
                        >
                            {countries.map((country: any) => (

                                <Select.Option
                                    key={`${country.name}${country.callingCodes[0]}`}
                                    mode="multiple"
                                    value={country.name}
                                >
                                    <div className={styles.countrySelect}>
                                        <img
                                            src={country.flag}
                                            alt={country.name}
                                            className={styles.flag}
                                            aria-label={`+${country.name[0]}`}
                                        />
                                        {country.name}
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        key="coverageCities"
                        name="coverageCities"
                        label={helpLabel('COVERAGE_CITIES', 'COVERAGE_CITIES_HELP')}
                        className={styles.formInput}
                        rules={[{ required: true, message: ca('FIELD_REQUIRED') }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            size="large"
                            className="signup-select"
                            tagRender={TagRender}
                            placeholder={cities.length === 0 ? 'Select a country...' : 'Select cities...'}
                            loading={loading}
                        >
                            {loading && <Select.Option> Loading... </Select.Option>}
                            {cities.map((city: any) => (
                                <Select.Option
                                    key={city}
                                    value={city}
                                >
                                    {city}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Button
                        type="primary"
                        className={cs(styles.button, 'lg')}
                        loading={loading}
                        onClick={onSubmit}
                    >
                        {ca('CONTINUE')}
                    </Button>
                </Space>
            </Form>
        </div>
    );
};

export default AboutYou;
