import { Select } from 'antd';

import cs from 'classnames';

import styles from './styles.module.scss';
import countryCodes from '../../const/country-codes';

const { Option } = Select;

interface CountryCodeSelectProps {
  initialValue?: string,
  className: string,
}

const CountryCodeSelect = ({ initialValue, className, ...props }: CountryCodeSelectProps) => {
    let initial;
    if (initialValue) {
        initial = countryCodes.findIndex(
            (country: any) => country.callingCodes[0] === initialValue.substring(1, initialValue.length),
        );
    } else {
        initial = countryCodes.findIndex((country: any) => country.name === country);
    }
    return (
        <Select
            showSearch
            placeholder="+593"
            defaultValue={`+${countryCodes[initial]?.callingCodes[0]}`}
            className={cs(className, styles.select)}
            {...props}
        >
            {
                countryCodes.map((countryCode: any) => (
                    <Option
                        key={countryCode.name}
                        value={`+${countryCode.callingCodes[0]}`}
                        label={`+${countryCode.callingCodes[0]}`}
                        className={styles.option}
                    >
                        <div>
                            <img
                                src={countryCode.flag}
                                className={styles.flag}
                                aria-label={`+${countryCode.callingCodes[0]}`}
                            />
                            {`+${countryCode.callingCodes[0]}`}
                        </div>
                    </Option>
                ))
            }
        </Select>
    );
};

export default CountryCodeSelect;
