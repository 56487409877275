import axios from 'axios';
import { BASE_URL, AUTH_URL, BASE_DASHBOARD_URL } from './index';

const validateEmail = (data: any) => axios.post(`${AUTH_URL}/validateEmail`, data);

const loginWithToken = (token: any) => axios.post(
    `${BASE_URL}/user/accessTokenLogin`,
    { deviceType: 'WEB' },
    {
        headers: {
            authorization: `Bearer ${token}`,
        },
    },
);

const login = (data: any) => axios.post(`${AUTH_URL}/login`, data);

const signup = (data: any) => axios.post(`${BASE_URL}/customer/registerFromEmail`, data);

const deliveryMarketingData = (payload: any) => axios.post(
    `${BASE_URL}/deliveryPartnerMarketingData/registerMarketingData`,
    payload,
);

const forgotPassword = (payload: any) => axios.post(`${AUTH_URL}/forgotPassword`, payload);

const updatePassword = (payload: any) => {
    const url = `${BASE_DASHBOARD_URL}/user/updatePasswordUser`;
    const query = axios.put(url, payload);
    return query;
};

export default {
    loginWithToken,
    validateEmail,
    login,
    signup,
    deliveryMarketingData,
    forgotPassword,
    updatePassword,
};
