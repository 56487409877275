/* eslint-disable no-unsafe-optional-chaining */
import {
    List,
    Avatar,
    Space,
    Tooltip,
} from 'antd';
import { BsQuestionCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import { Booking, PriceBreakUp as PriceBreakUpType } from '../../types/booking';
import { PaymentMethod as PaymentMethodType } from '../../types/payment';

import IMAGES from '../../const/images';
import styles from './styles.module.scss';

import ItemPrice from './ItemPrice';
import PaymentMethod from './PaymentMethod';

interface PriceBreakUpProps extends Partial<Booking>{
  priceBreakUpData: PriceBreakUpType;
}

const PriceBreakUp = ({
    priceBreakUpData,
    paymentMethod,
    sumOrderAndDelivery,
    ServiceAmount,
    deliveryFee,
    currency,
}: PriceBreakUpProps) => {
    const { t: to } = useTranslation('bookings', { keyPrefix: 'ORDER_FINISHED' });

    const isDeliver = paymentMethod === PaymentMethodType.CASH;
    const purchasedProduct = sumOrderAndDelivery
        ? (priceBreakUpData?.orderAmount - priceBreakUpData?.businessDeliveryFee)
        : priceBreakUpData?.orderAmount;
    const myDeliveryFee = ServiceAmount || 0;
    const total = purchasedProduct + myDeliveryFee;
    const pickerFee = !sumOrderAndDelivery
        ? deliveryFee?.toFixed(2)
        : (priceBreakUpData?.baseFare + priceBreakUpData?.perKmCharge).toFixed(2);
    return (
        <Space direction="vertical" size={24} className="fullWidth">
            <span className="bold left cl--primary-dark-blue-3 paragraph--2">
                { to('PRICE_BREAKUP') }
            </span>
            <PaymentMethod paymentMethod={paymentMethod} />
            <div>

                <List.Item>
                    <span className="bold left cl--secondary-purple paragraph--2">
                        {to('BREAKUP_DETAILS')}
                    </span>
                </List.Item>

                {
                    isDeliver
                        ? (
                            <div>
                                <ItemPrice
                                    line
                                    avatar={
                                        <Avatar shape="square" src={IMAGES.icons.package} />
                                    }
                                    title={to('PURCHASED_PRODUCT')}
                                    description={`$${purchasedProduct.toFixed(2)}`}
                                />
                                <ItemPrice
                                    color="#D1DDFF"
                                    avatar={
                                        <Avatar shape="square" src={IMAGES.icons.money} />
                                    }
                                    title={to('MY_DELIVERY_PRICE')}
                                    description={`$${myDeliveryFee.toFixed(2)}`}
                                />
                                <br />
                                <ItemPrice
                                    title={(
                                        <div className="flex-row start-row-center">
                                            <span className="bold left cl--primary-light-blue paragraph--2">
                                                {to('SERVICE_TOTAL')}
                                            </span>
                                            <Tooltip placement="bottom" title={to('FINAL_PRICE')}>
                                                <BsQuestionCircle color="#888" size={14} style={{ marginLeft: 8 }} />
                                            </Tooltip>
                                        </div>
                                    )}
                                    description={(
                                        <div
                                            style={{ fontWeight: 600, fontSize: 18 }}
                                        >
                                            {`$${total.toFixed(2)}`}
                                            <span style={{ fontWeight: 500, fontSize: 12 }}>
                                                { currency || 'USD' }
                                            </span>
                                        </div>
                                    )}
                                />
                                <div className={styles.separate} />
                            </div>
                        )
                        : <div />
                }

                <Space size={4} direction="vertical">
                    <ItemPrice
                        avatar={
                            <Avatar shape="square" src={IMAGES.icons.delivery} />
                        }
                        color="#F0FCFF"
                        title={to('BASE_RATE')}
                        description={`$${pickerFee} ${currency}`}
                    />
                    <span className={styles.noteFee}>{to('NOTE_PICKER_FEE')}</span>
                </Space>
            </div>
        </Space>
    );
};

export default PriceBreakUp;
