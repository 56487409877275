import cs from 'classnames';
import {
    Form, Space, Input, Button, Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from '../../reducers/root';
import { startResetPassword } from '../../actions/auth';

import styles from './styles.module.scss';

const ConfirmForm = () => {
    const { t } = useTranslation('login');

    const { loading, msgError } = useSelector((state: RootState) => state.ui);

    const [params] = useSearchParams();
    const dispatch: any = useDispatch();

    const handleSubmit = ({ newPassword }: { newPassword: string }) => {
        const passwordResetToken = params.get('token');
        const payload = {
            newPassword,
            passwordResetToken,
        };
        dispatch(startResetPassword(payload));
    };

    return (
        <Form onFinish={handleSubmit} layout="vertical">
            <Space size={32} direction="vertical">

                {(msgError) && (<Alert message={msgError} type="error" />)}

                <Form.Item
                    name="password"
                    label={t('NEW_PASSWORD')}
                    className={styles.formInput}
                    rules={[{ required: true, message: t('REQUIRED') }]}
                >
                    <Input
                        size="large"
                        placeholder={t('NEW_PASSWORD')}
                        disabled={loading}
                        type="password"
                    />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    dependencies={['password']}
                    label={t('REPEAT_PASSWORD')}
                    className={styles.formInput}
                    rules={[
                        { required: true, message: t('REQUIRED') },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('PASSWORDS_NOT_MATCH')));
                            },
                        }),
                    ]}
                >
                    <Input
                        size="large"
                        placeholder={t('REPEAT_PASSWORD')}
                        disabled={loading}
                        type="password"
                    />
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className={cs(styles.button, 'fullWidth')}
                    loading={loading}
                >
                    {t('RESET_PASSWORD')}
                </Button>
            </Space>
        </Form>
    );
};

export default ConfirmForm;
