import { Typography, Image, Row } from 'antd';
import styles from './style.module.scss';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

interface IImagePreviewSection {
  src: string | null;
}

const { Title } = Typography;

const ImagePreviewSection = ({
  src
}: IImagePreviewSection) => {

  const { t: cw } = useTranslation('onboarding', { keyPrefix: 'CREATE_WORKSPACE' });

  return (
    <>
      {src ?
        <Row style={{marginTop: 16}} justify='center' align='middle'>
          <Image width={240} height={240} src={src} />
        </Row>

        :
        <div className={cs(styles.emptyImage)}>
          <Title level={5}>{cw("NOT_LOGO_UPLOADED")}</Title>
        </div>
      }
    </>
  )
}

export default ImagePreviewSection;