import {
    Row, Col, Form, Space, Alert, Input, Button, Select, Avatar,
} from 'antd';
import cs from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from '../../styles/screens/NewDriver.module.scss';
import IMAGES from '../../const/images';
import { CountryCodes } from '../../enum/countryCode.enum';
import { PhoneNumber } from '../../widgets/PhoneNumber';

const { Option } = Select;

interface DriverInformationProps {
  // eslint-disable-next-line no-unused-vars
  setDriverData: (driverData: any) => void;
  nextStep: () => void;
  driverData: any;
}

const DriverInformation = ({ setDriverData, nextStep, driverData } : DriverInformationProps) => {
    const { t: ca } = useTranslation('onboarding', { keyPrefix: 'CREATE_ACCOUNT' });
    const { t: tr } = useTranslation('shared', { keyPrefix: 'RESPONSE_ACTIONS' });
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });

    const { auth: user } = useSelector((state: any) => state);

    const [form] = Form.useForm();
    const [defaultCountryCode, setDefaultCountryCode] = useState<string>(
    /* @ts-ignore */
        `+${CountryCodes[user.countryCode] || '+593'}`,
    );
    const [error, setError] = useState<any>();

    const onSubmit = async (values: any) => {
        const data = { ...values };
        setError(null);
        setDriverData(data);
        nextStep();
    };

    const fieldProps = {
        label: ca('PHONE'),
        keyCountryCode: 'countryCode',
        keyMobile: 'mobile',
    };

    const handleSelect = (value: string) => {
        form.setFieldValue('countryCode', value);
        setDefaultCountryCode(value);
    };

    useEffect(() => {
        const countryCode = user.countryCode || '+593';
        handleSelect(countryCode);
    }, [user]);

    return (
        <div className="fullWidth fullHeight">
            <Space direction="vertical" size={24} className="fullWidth">
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout="vertical"
                    className={styles.form}
                    initialValues={{ language: 'ES', currency: 'USD', ...driverData }}
                >
                    <Space direction="vertical" size={24}>
                        {error && (
                            <Alert message={error} type="error" />
                        )}
                        <Row justify="start" align="top" gutter={[24, 24]} className="noMargin">
                            <Col xs={24} md={24} lg={12}>
                                <Space direction="vertical" size={24}>
                                    <Form.Item
                                        name="name"
                                        label={ca('FIRST_NAME')}
                                        className={styles.formInput}
                                        rules={[{ required: true, message: tr('REQUIRED') }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={ca('FIRST_NAME')}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="lastName"
                                        label={ca('LAST_NAME')}
                                        className={styles.formInput}
                                        rules={[{ required: true, message: tr('REQUIRED') }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={ca('LAST_NAME')}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="language"
                                        label={td('LANGUAGE')}
                                        className={styles.formInput}
                                        rules={[{ required: true, message: tr('REQUIRED') }]}
                                    >
                                        <Select size="large" className="signup-select">
                                            <Option value="ES">
                                                <div className={styles.flag}>
                                                    <Avatar
                                                        size={16}
                                                        src={IMAGES.icons.es_flag}
                                                        className={styles.icon}
                                                    />
                                                    {td('SPANISH')}
                                                </div>
                                            </Option>
                                            <Option value="EN">
                                                <div className={styles.flag}>
                                                    <Avatar
                                                        size={16}
                                                        src={IMAGES.icons.en_flag}
                                                        className={styles.icon}
                                                    />
                                                    {td('ENGLISH')}
                                                </div>
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Space>
                            </Col>
                            <Col xs={24} md={24} lg={12}>
                                <Space direction="vertical" size={24}>
                                    <Form.Item
                                        name="email"
                                        label={ca('EMAIL')}
                                        className={styles.formInput}
                                        rules={[{ type: 'email', message: tr('REQUIRED') }, { required: true }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={ca('EMAIL')}
                                        />
                                    </Form.Item>
                                    <PhoneNumber
                                        form={form}
                                        fieldProps={fieldProps}
                                        handleSelect={handleSelect}
                                        defaultCountryCode={defaultCountryCode}
                                        styles={styles}
                                    />
                                    <div className={styles.inputSelect}>
                                        <Form.Item
                                            name="currency"
                                            label={td('CURRENCY')}
                                            className={styles.formInput}
                                            rules={[{ required: true, message: tr('REQUIRED') }]}
                                        >
                                            <Select size="large" className="signup-select">
                                                <Option value="USD">$ USD</Option>
                                                <Option value="MXN">$ MXN</Option>
                                                <Option value="ARS">$ ARS</Option>
                                                <Option value="UYU">$ UYU</Option>
                                                <Option value="CRC">₡ CRC</Option>
                                                <Option value="PEN">S/ PEN</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Space>
                            </Col>
                        </Row>
                    </Space>
                </Form>
                <Button
                    type="primary"
                    className={cs(styles.button, 'lg')}
                    onClick={() => form.submit()}
                >
                    {ca('CONTINUE')}
                </Button>
            </Space>
        </div>
    );
};

export default DriverInformation;
