import cs from 'classnames';
import InputNumber from '../InputNumber';

const InputPhone = ({
    value, onChange, styles, phoneError, ...props
}:any) => {
    const handleOnBlur = () => {
        const number = value?.charAt(0) === '0' ? value.slice(1, value.length) : value;
        onChange(number);
    };

    const handleChange = ({ target }: any) => {
        if (target.value.length > 10) return;
        onChange(target.value);
    };
    return (
        <InputNumber
            className={cs(
                styles.phone,
                { [styles.error]: phoneError.length > 0 },
            )}
            value={value}
            onWheel={(e: any) => e.target.blur()}
            onChange={handleChange}
            onBlur={handleOnBlur}
            placeholder="123456789"
            {...props}
        />
    );
};

export default InputPhone;
