/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';

import { authReducer, UserState } from './auth';
import { uiReducer } from './ui';
import { workspaceReducer, WorkspaceState } from './workspace';

export type RootState = {
  auth: UserState,
  ui: any,
  workspace: WorkspaceState,
}

export interface Action {
  readonly type: string;
  readonly payload?: any;
}

const rootState = {
    auth: authReducer,
    ui: uiReducer,
    workspace: workspaceReducer,
};

export const rootReducer = combineReducers(rootState);
