import cs from 'classnames';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import IMAGES from '../const/images';
import Header from '../components/Onboarding/Header';
import Footer from '../components/Onboarding/Footer';
import ConfirmForm from '../components/ResetPassword/ConfirmForm';

import styles from '../styles/screens/Onboarding.module.scss';

const ConfirmPassword = () => {
    const { t } = useTranslation('login');

    return (
        <div
            style={{ backgroundImage: `url(${IMAGES.login.spirals})` }}
            className={cs(styles.pageLayout, styles.reset, 'flex-column center-column-center')}
        >
            <Space
                direction="vertical"
                size={40}
                style={{
                    maxWidth: 570,
                    margin: '0 auto',
                }}
            >
                <Header />
                <span className="cl--secondary-purple title--header-1 center">
                    { t('CONFIRM_PASSWORD') }
                </span>
                <span className="cl--secondary-purple paragraph--2 center medium">
                    { t('CONFIRM_PASSWORD_HELP')}
                </span>
                <Space direction="vertical" size={72}>
                    <ConfirmForm />
                    <Footer />
                </Space>
            </Space>
        </div>
    );
};

export default ConfirmPassword;
