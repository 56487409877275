import {
    DRIVER_LOGO_BLUE,
    DRIVER_LOGO_PURPLE,
    DRIVER_LOGO_YELLOW,
} from './envs';

const IMAGES = {
    logo: {
        box: 'https://picker-dashboard.s3.amazonaws.com/logos/logo.svg',
        iso: 'https://picker-dashboard.s3.amazonaws.com/logos/Isotipo.svg',
        horizontal: 'https://picker-dashboard.s3.amazonaws.com/logos/logo_horizontal.svg',
        provisional: 'https://picker-small-fleets.s3.amazonaws.com/icons/provisional.svg',
    },
    login: {
        spirals: 'https://picker-small-fleets.s3.amazonaws.com/backgrounds/spirals.svg',
    },
    social: {
        facebook_circle: 'https://picker-dashboard.s3.amazonaws.com/logos/facebook_circle.png',
        google: 'https://picker-dashboard.s3.amazonaws.com/logos/google.svg',
    },
    onboarding: {
        box: 'https://picker-dashboard.s3.amazonaws.com/images/onboarding_super_box.svg',
        completed: 'https://picker-small-fleets.s3.amazonaws.com/icons/delivery_partner_completed.svg',
    },
    store: {
        create_store: 'https://picker-dashboard.s3.amazonaws.com/images/create_store.svg',
        upload_store_csv: 'https://picker-dashboard.s3.amazonaws.com/images/upload_store_csv.svg',
        new_store: 'https://picker-dashboard.s3.amazonaws.com/icons/icon-new.svg',
    },
    booking: {
        send: 'https://picker-dashboard.s3.amazonaws.com/home/send_company.svg',
        collect_and_delivery: 'https://picker-dashboard.s3.amazonaws.com/booking/collect_and_deliver.svg',
        store_location: 'https://picker-dashboard.s3.amazonaws.com/icons/store_location.svg',
        customer_location: 'https://picker-dashboard.s3.amazonaws.com/icons/customer_location.svg',
    },
    icons: {
        home: 'https://picker-small-fleets.s3.amazonaws.com/icons/home.svg',
        wave: 'https://picker-dashboard.s3.amazonaws.com/icons/wave.svg',
        money: 'https://picker-dashboard.s3.amazonaws.com/icons/money.svg',
        box: 'https://picker-dashboard.s3.amazonaws.com/icons/box.svg',
        stars: 'https://picker-dashboard.s3.amazonaws.com/icons/stars.svg',
        infoCircle: 'https://picker-dashboard.s3.amazonaws.com/icons/info_circle.svg',
        size: 'https://picker-dashboard.s3.amazonaws.com/icons/size.svg',
        safe: 'https://picker-dashboard.s3.amazonaws.com/icons/safe.svg',
        weight: 'https://picker-dashboard.s3.amazonaws.com/icons/weight.svg',
        delivery: 'https://picker-dashboard.s3.amazonaws.com/icons/delivery.svg',
        package: 'https://picker-small-fleets.s3.amazonaws.com/icons/package.svg',
        whatsapp: 'https://picker-dashboard.s3.amazonaws.com/icons/whatsapp.svg',
        sms: 'https://picker-dashboard.s3.amazonaws.com/icons/SMS.svg',
        email: 'https://picker-dashboard.s3.amazonaws.com/icons/gmail.svg',
        deliver_box: 'https://picker-dashboard.s3.amazonaws.com/icons/deliver-box.svg',
        deliver_box_alt: 'https://picker-dashboard.s3.amazonaws.com/icons/deliver-box-alt.svg',
        driver: 'https://picker-small-fleets.s3.amazonaws.com/icons/driver.svg',
        store_location: 'https://picker-dashboard.s3.amazonaws.com/icons/store_location.svg',
        customer_location: 'https://picker-dashboard.s3.amazonaws.com/icons/customer_location.svg',
        my_fleet: 'https://picker-dashboard.s3.amazonaws.com/icons/icon-my-fleet.svg',
        settings: 'https://picker-dashboard.s3.amazonaws.com/icons/settings.svg',
        alert: 'https://picker-dashboard.s3.amazonaws.com/icons/icon-alert.svg',
        es_flag: 'https://picker-flags.s3.amazonaws.com/es.png',
        en_flag: 'https://picker-flags.s3.amazonaws.com/us.png',
        ec_flag: 'https://picker-flags.s3.amazonaws.com/ec.png',
        mx_flag: 'https://picker-flags.s3.amazonaws.com/mx.png',
    },
    signup: {
        color_lines: 'https://picker-dashboard.s3.amazonaws.com/images/color_lines.svg',
        city: 'https://picker-dashboard.s3.amazonaws.com/images/city.svg',
    },
    payment_methods: {
        cash: 'https://picker-small-fleets.s3.amazonaws.com/icons/cash.svg',
        billing: 'https://picker-small-fleets.s3.amazonaws.com/icons/billing.svg',
    },
    images: {
        delivery: 'https://picker-small-fleets.s3.amazonaws.com/images/delivery.svg',
    },
    driver: {
        blue: DRIVER_LOGO_BLUE,
        yellow: DRIVER_LOGO_YELLOW,
        purple: DRIVER_LOGO_PURPLE,
    },
};

export default IMAGES;
