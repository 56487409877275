import { useSelector } from 'react-redux';

import SwitchLanguage from '../SwitchLanguage';
import styles from './styles.module.scss';

const Header = () => {
    const { workspace } = useSelector((state: any) => state);

    return (
        <div className={styles.options}>
            <span className="center cl--secondary-purple bold title--header-2">
                { workspace.active.name }
            </span>
            <SwitchLanguage />
        </div>
    );
};

export default Header;
