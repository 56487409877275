import {
    Row, Col, Form, Space, Alert, Input, Button, Select,
} from 'antd';
import cs from 'classnames';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../../styles/screens/NewDriver.module.scss';

const { Option } = Select;

interface VehicleInformationProps {
  // eslint-disable-next-line no-unused-vars
  setVehicleData: (vehicleData: any) => void;
  nextStep: () => void;
  vehicleData: any;
}

const VehicleInformation = ({ setVehicleData, nextStep, vehicleData } : VehicleInformationProps) => {
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });
    const { t: ta } = useTranslation('shared', { keyPrefix: 'ACTIONS' });
    const { t: tr } = useTranslation('shared', { keyPrefix: 'RESPONSE_ACTIONS' });

    const [form] = Form.useForm();
    const [error, setError] = useState<any>();

    const onSubmit = async (values: any) => {
        const data = { ...values };
        setError(null);
        setVehicleData(data);
        nextStep();
    };

    return (
        <div className="fullWidth fullHeight">
            <Space direction="vertical" size={24} className="fullWidth">
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout="vertical"
                    initialValues={{ vehicleType: 'BIKE', ...vehicleData }}
                >
                    <Space direction="vertical" size={24}>
                        {error && (
                            <Alert message={error} type="error" />
                        )}
                        <Row justify="start" align="top" gutter={[24, 24]} className="noMargin">
                            <Col xs={24} md={24} lg={12}>
                                <Space direction="vertical" size={24}>
                                    <Form.Item
                                        name="vehicleModelName"
                                        label={td('VEHICLE_MODEL_NAME')}
                                        className={styles.formInput}
                                        rules={[{ required: true, message: tr('REQUIRED') }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={td('VEHICLE_MODEL_NAME')}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="vehicleType"
                                        label={td('VEHICLE_TYPE')}
                                        className={styles.formInput}
                                        rules={[{ required: true, message: tr('REQUIRED') }]}
                                    >
                                        <Select size="large" className="signup-select">
                                            <Option value="LITE">{td('CAR')}</Option>
                                            <Option value="BIKE">{td('BIKE')}</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="vehicleYear"
                                        label={td('VEHICLE_YEAR')}
                                        className={styles.formInput}
                                        rules={[{ required: true, message: tr('REQUIRED') }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={td('VEHICLE_YEAR')}
                                        />
                                    </Form.Item>
                                </Space>
                            </Col>
                            <Col xs={24} md={24} lg={12}>
                                <Space direction="vertical" size={24}>
                                    <Form.Item
                                        name="MCNumber"
                                        label={td('MC_NUMBER')}
                                        className={styles.formInput}
                                        rules={[{ required: true, message: tr('REQUIRED') }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={td('MC_NUMBER')}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="vehicleColor"
                                        label={td('VEHICLE_COLOR')}
                                        className={styles.formInput}
                                        rules={[{ required: true, message: tr('REQUIRED') }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={td('VEHICLE_COLOR')}
                                        />
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    </Space>
                </Form>
                <Button
                    type="primary"
                    className={cs(styles.button, 'lg')}
                    onClick={() => form.submit()}
                >
                    {ta('CONTINUE')}
                </Button>
            </Space>
        </div>
    );
};

export default VehicleInformation;
