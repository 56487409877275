import { Col, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TLogoSelection } from '@/types/workspace';
import cs from 'classnames';

import stylesOnb from '@/styles/screens/Onboarding.module.scss';
import styles from './style.module.scss'
import UploadLogo from './UploadLogo';
import DefaultLogo from './DefaultLogo';
import { useSelector } from 'react-redux';
import { LOGOS } from '@/const/logos';

const { Text } = Typography;

interface ISelectLogoContent {
    showTitle?: boolean;
    setDefaultLogo?: (param:string) => void
}

const SelectLogoContent = ({
    showTitle,
    setDefaultLogo
}: ISelectLogoContent) => {

    const [logoSelection, setLogoSelection] = useState<TLogoSelection>("UPLOAD");
    const [currentSrc, setCurrentSrc] = useState<string | null>(null);
    const [existDefaultLogo,setExisteDefaultLogo] = useState<null | string>(null);

    const { t: cw } = useTranslation('onboarding', { keyPrefix: 'CREATE_WORKSPACE' });

    const { workspace: { active } } = useSelector((state: any) => state);

    useEffect(() => {
      if(!active || !active.image) return;
      
      const findLogo = LOGOS.driver.find((logo) => logo.url === active.image);

      if(findLogo){
        setLogoSelection('SELECT');
        setExisteDefaultLogo(findLogo.type);
      }else{
        setCurrentSrc(active.image);
        setLogoSelection('UPLOAD');
      }
    },[active]);

    return (
        <Space direction="vertical" size={16}>
            {showTitle ? <div className={stylesOnb.header}>
                <span className="title--header-2 cl--secondary-purple">
                    {cw('ENTER_YOUR_LOGO')}
                </span>
            </div> : null}

            <span className="paragraph--2 center">
                {cw('ENTER_YOUR_LOGO_DESCRIPTION')}
            </span>

            <Row gutter={48} justify="space-evenly">
                <Col >
                    <Text
                        onClick={() => setLogoSelection("UPLOAD")}
                        className={cs(
                            styles.option,
                            { [styles.active]: logoSelection == "UPLOAD" },
                        )}
                    >
                        {cw("HAVE_A_LOGO")}
                    </Text>
                </Col>
                <Col>
                    <Text
                        onClick={() => setLogoSelection("SELECT")}
                        className={cs(
                            styles.option,
                            { [styles.active]: logoSelection == "SELECT" },
                        )}
                    >
                        {cw("DONT_HAVE_A_LOGO")}
                    </Text>
                </Col>
            </Row>
            <div className={cs(styles.selectionContent)}>
                {logoSelection === "UPLOAD" ? <UploadLogo setCurrentSrc={setCurrentSrc} currentSrc={currentSrc} /> : <DefaultLogo existDefaultLogo={existDefaultLogo} setDefaultLogo={setDefaultLogo}/>}
            </div>
        </Space>
    )
}

export default SelectLogoContent;