import { Input } from 'antd';

const InputNumber = ({ ...props }) => {
    const validateBeforeChange = (event: any) => {
        if (event.key === 'e') {
            event.preventDefault();
        }
    };
    return (
        <Input type="number" onKeyDown={validateBeforeChange} {...props} />
    );
};

export default InputNumber;
