import { TDefaultLogo } from "@/types/workspace";

export const LOGOS = {
    driver:[{
        type: 'purple',
        color: '#6C8EEF',
        background: '#DFE7FF',
        url: 'https://picker-small-fleets.s3.amazonaws.com/images/smallfleet_purple.svg',
    },
    {
        type: 'blue',
        color: '#3883FF',
        background: '#C0D8FF',
        url: 'https://picker-small-fleets.s3.amazonaws.com/images/smallfleet_blue.svg',
    },
    {
        type: 'yellow',
        color: '#FFD875',
        background: '#FBF4DB',
        url: 'https://picker-small-fleets.s3.amazonaws.com/images/smallfleet_yellow.svg',
    },
    ]
}