import { startLogout } from '@/actions/auth';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const Subheader = () => {
    const dispatch = useDispatch<any>();
    const { t } = useTranslation('onboarding');
    const { t: ca } = useTranslation('onboarding', { keyPrefix: 'CREATE_ACCOUNT' });

    return (
        <Space direction="vertical" size={16}>
            <span className="cl--secondary-purple title--header-1 center">
                { t('WANT_DELIVERY_PARTNER') }
            </span>
            <div className="flex-row center-row-center">
                <Space direction="horizontal" size={8}>
                    <span className="cl--secondary-purple paragraph--2 center light">
                        { t('IS_MEMBER')}
                    </span>
                    <Link to="/login" onClick={() => dispatch(startLogout())}>
                        <span className="cl--secondary-purple paragraph--2 center medium link">
                            { ca('LOGIN')}
                        </span>
                    </Link>
                </Space>
            </div>
        </Space>
    );
};

export default Subheader;
