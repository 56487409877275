import {
    List,
    Row,
    Col,
    Space,
    Avatar,
    Typography,
} from 'antd';

import { useTranslation } from 'react-i18next';

import { Booking } from '../../types/booking';
import { PAYMENT_METHODS } from '../../const/paymentMethods';

import IMAGES from '../../const/images';
import styles from './styles.module.scss';

const { Text } = Typography;

const PaymentMethod = ({ paymentMethod }: Partial<Booking>) => {
    const { t: to } = useTranslation('bookings', { keyPrefix: 'ORDER_FINISHED' });
    return (
        <List.Item className="noPadding">
            <Row className="fullWidth" align="middle" justify="space-between">
                <Col span={12}>
                    <span className="bold left cl--secondary-purple paragraph--2">
                        {to('PAYMENT_METHOD')}
                    </span>
                </Col>
                <Col span={12} style={{ float: 'right' }} className={styles.iconPayment}>
                    <div className={styles.containerIconPayment}>
                        <Space
                            direction="vertical"
                            size={4}
                            className="fullWidth flex-column center-column-center"
                        >
                            <Avatar
                                className="avatar"
                                shape="square"
                                size={45}
                                src={
                                    paymentMethod === PAYMENT_METHODS.CASH
                                        ? IMAGES.payment_methods.cash
                                        : IMAGES.payment_methods.billing
                                }
                            />
                            <Text strong>
                                {paymentMethod === PAYMENT_METHODS.CARD && (
                                    to('CARD')
                                )}
                                {paymentMethod === PAYMENT_METHODS.CASH && (
                                    to('CASH')
                                )}
                                {(
                                    paymentMethod === PAYMENT_METHODS.BILLING_TRANSFER
                  || paymentMethod === PAYMENT_METHODS.BILLING_CARD
                                ) && (to('ADD_TO_BILLING')
                                )}
                            </Text>
                        </Space>
                    </div>
                </Col>
            </Row>
        </List.Item>
    );
};

export default PaymentMethod;
