import {
    Form, notification, Space,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import styles from '@/styles/screens/Onboarding.module.scss';
import { createWorkspace } from '@/api/workspace';
import { changeWorkspace, setWorkspaces, startLoadWorkspaces } from '@/actions/workspace';
import { registerMarketingData } from '@/actions/auth';
import userAPI from '../../../api/user';
import AboutYou from './AboutYou';
import Workspace from './Workspace';

const CreateWorkspaceContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const [keyName, setKeyName] = useState('aboutYou');
    const [loading, setLoading] = useState(false);
    const [workspaceData, setWorkspaceData] = useState<any>();
    const { t: ra } = useTranslation('shared', { keyPrefix: 'RESPONSE_ACTIONS' });

    const [form] = Form.useForm();

    // eslint-disable-next-line no-unused-vars
    const handleSubmit = async (values: any) => {
        try {
            setLoading(true);

            const {
                deliveriesNumber,
                makeSendAndCollect,
                coverageDistance,
                baseFare,
                coverageCities,
                country,
            } = workspaceData;

            const marketingData = {
                deliveriesNumber,
                makeSendAndCollect,
                coverageDistance,
                baseFare,
                coverageCities,
                country,
            };

            delete workspaceData.deliveriesNumber;
            delete workspaceData.makeSendAndCollect;

            const { body }: any = await createWorkspace({ ...workspaceData });

            await userAPI.deliveryMarketingData(marketingData);

            dispatch(setWorkspaces([body]));
            dispatch(changeWorkspace(body));
            dispatch(startLoadWorkspaces());
            dispatch(registerMarketingData());
            navigate('/onboarding/completed');
        } catch (error) {
            if (error instanceof AxiosError) {
                notification.error({
                    message: ra('ERROR'),
                    description: error.response?.data?.message,
                });
            }
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const STEP_DATA: any = {
        aboutYou: {
            component: AboutYou,
        },
        workspace: {
            component: Workspace,
        },
    };

    const renderCurrentStep = (props: any) => {
        const Component = STEP_DATA[keyName].component;
        return <Component setData={() => { }} {...props} />;
    };

    const handleFormData = () => form.submit();

    return (
        <Space direction="vertical" size={24}>
            <Form.Provider
                onFormFinish={(name, { values }) => {
                    if (keyName === 'workspace') {
                        handleSubmit(values);
                    } else {
                        setKeyName('workspace');
                    }
                }}
            >
                <div className={styles.header}>
                    {renderCurrentStep({
                        form,
                        workspaceData,
                        setWorkspaceData,
                        handleFormData,
                        loading,
                    })}
                </div>
            </Form.Provider>
        </Space>
    );
};

export default CreateWorkspaceContent;
