/* eslint-disable no-unused-vars */
export enum CountryCodes {
    EC = '593',
    MX = '52',
    DO = '1849',
    PE = '51',
    CO = '57',
    GT = '502',
    CR = '506',
    CL = '56',
    AR = '54',
    UY = '598',
    PA = '507',
}
