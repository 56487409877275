import {
    Space, Avatar, notification, Spin, Row, Col, Form, Alert, Input, Button, Select,
} from 'antd';
import cs from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AiFillIdcard, AiOutlineCar } from 'react-icons/ai';
import { CountryCodeSelect, InputPhone } from '../PhoneSelector';
import { RootState } from '../../reducers/root';
import { getDriverByID, updateDriver } from '../../api/drivers';

import IMAGES from '../../const/images';
import HeaderSection from '../HeaderSection';

import styles from '../../styles/screens/NewDriver.module.scss';
import stylesCard from '../DriverCard/styles.module.scss';

const { Option } = Select;

const UpdateDriver = () => {
    const { t: td } = useTranslation('drivers', { keyPrefix: 'DRIVERS' });
    const { t: ts } = useTranslation('shared', { keyPrefix: 'ACTIONS' });
    const { t: ca } = useTranslation('onboarding', { keyPrefix: 'CREATE_ACCOUNT' });
    const { t: tr } = useTranslation('shared', { keyPrefix: 'RESPONSE_ACTIONS' });

    const params = useParams();
    const navigate = useNavigate();
    const { active } = useSelector((state: RootState) => state.workspace);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [driver, setDriver] = useState<any>();
    const [phoneError, setPhoneError] = useState<string[]>([]);
    const [error, setError] = useState<any>();

    const handleError = () => setPhoneError(form.getFieldError('mobile'));

    const headers:any = [
        {
            icon: <AiFillIdcard color="#6C8EEF" size={24} />,
            title: td('DRIVER_INFORMATION'),
        },
        {
            icon: <AiOutlineCar color="#6C8EEF" size={24} />,
            title: td('VEHICLE_INFORMATION'),
        },
    ];

    const loadForm = (body: any) => {
        const user = { ...body };
        delete user.driverID;
        const driver = { ...body.driverID };
        const data = { ...user, ...driver, ...user?.preference };
        form.setFieldsValue(data);
    };

    const getDriver = () => {
        setLoading(true);
        const queryParams = {
            driverID: params?.id,
            smallFleetID: active?._id,
        };
        getDriverByID(queryParams)
            .then(({ body }: any) => {
                setDriver(body);
                loadForm(body);
            })
            .catch((error: any) => {
                const description = error.response.data.message;
                notification.error({
                    message: td('DRIVER_NOT_OBTAINED'),
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!params.id) return;
        getDriver();
    }, [active?._id, params.id]);

    const onSubmit = async (values: any) => {
        const payload = {
            ...values,
            userID: params.id,
            smallFleetID: active?._id,
        };
        setError(null);
        updateDriver(payload)
            .then(() => {
                notification.success({
                    message: td('DRIVER_UPDATED'),
                    description: '',
                });
                setDriver({});
                getDriver();
            })
            .catch((error: any) => {
                const description = error.response.data.message;
                notification.error({
                    message: td('DRIVER_NO_UPDATED'),
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const renderDriverForm = () => (
        <Row justify="start" align="top" gutter={[24, 24]} className="noMargin">
            <Col xs={24} md={24} lg={12}>
                <Space direction="vertical" size={24}>
                    <Form.Item
                        name="name"
                        label={ca('FIRST_NAME')}
                        className={styles.formInput}
                        rules={[{ required: true, message: tr('REQUIRED') }]}
                    >
                        <Input
                            size="large"
                            placeholder={ca('FIRST_NAME')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        label={ca('LAST_NAME')}
                        className={styles.formInput}
                        rules={[{ required: true, message: tr('REQUIRED') }]}
                    >
                        <Input
                            size="large"
                            placeholder={ca('LAST_NAME')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="language"
                        label={td('LANGUAGE')}
                        className={styles.formInput}
                        rules={[{ required: true, message: tr('REQUIRED') }]}
                    >
                        <Select size="large" className="signup-select">
                            <Option value="ES">
                                <div className={styles.flag}>
                                    <Avatar
                                        size={16}
                                        src={IMAGES.icons.es_flag}
                                        className={styles.icon}
                                    />
                                    {td('SPANISH')}
                                </div>
                            </Option>
                            <Option value="EN">
                                <div className={styles.flag}>
                                    <Avatar
                                        size={16}
                                        src={IMAGES.icons.en_flag}
                                        className={styles.icon}
                                    />
                                    {td('ENGLISH')}
                                </div>
                            </Option>
                        </Select>
                    </Form.Item>
                </Space>
            </Col>
            <Col xs={24} md={24} lg={12}>
                <Space direction="vertical" size={24}>
                    <Form.Item
                        name="email"
                        label={ca('EMAIL')}
                        className={styles.formInput}
                        rules={[{ type: 'email', message: tr('REQUIRED') }, { required: true }]}
                    >
                        <Input
                            size="large"
                            placeholder={ca('EMAIL')}
                        />
                    </Form.Item>
                    <div className={styles.inputPhone}>
                        <div className="ant-col ant-form-item-label">
                            {ca('PHONE')}
                        </div>
                        <div className="ant-col ant-form-item-control">
                            <div
                                className={
                                    cs(
                                        'signup-phone',
                                        {
                                            'create-booking-phone--error': phoneError.length > 0,
                                        },
                                    )
                                }
                            >
                                <Form.Item
                                    name="countryCode"
                                    initialValue="+593"
                                    className={styles.formInput}
                                    style={{ marginBottom: 0 }}
                                >
                                    <CountryCodeSelect className="create-booking-country-code" />
                                </Form.Item>
                                <Form.Item
                                    name="mobile"
                                    noStyle
                                    rules={[{ required: true, message: tr('REQUIRED') }]}
                                >
                                    <InputPhone
                                        className="create-booking-phone-number"
                                        placeholder="123456789"
                                        onChange={handleError}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className={styles.inputSelect}>
                        <Form.Item
                            name="currency"
                            label={td('CURRENCY')}
                            className={styles.formInput}
                            rules={[{ required: true, message: tr('REQUIRED') }]}
                        >
                            <Select size="large" className="signup-select">
                                <Option value="USD">$ USD</Option>
                                <Option value="MXN">$ MXN</Option>
                                <Option value="ARS">$ ARS</Option>
                                <Option value="UYU">$ UYU</Option>
                                <Option value="CRC">₡ CRC</Option>
                                <Option value="PEN">S/ PEN</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </Space>
            </Col>
        </Row>
    );

    const renderVehicleForm = () => (
        <Row justify="start" align="top" gutter={[24, 24]} className="noMargin">
            <Col xs={24} md={24} lg={12}>
                <Space direction="vertical" size={24}>
                    <Form.Item
                        name="vehicleModelName"
                        label={td('VEHICLE_MODEL_NAME')}
                        className={styles.formInput}
                        rules={[{ required: true, message: tr('REQUIRED') }]}
                    >
                        <Input
                            size="large"
                            placeholder={td('VEHICLE_MODEL_NAME')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="vehicleType"
                        label={td('VEHICLE_TYPE')}
                        className={styles.formInput}
                        rules={[{ required: true, message: tr('REQUIRED') }]}
                    >
                        <Select size="large" className="signup-select">
                            <Option value="LITE">{td('CAR')}</Option>
                            <Option value="BIKE">{td('BIKE')}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="vehicleYear"
                        label={td('VEHICLE_YEAR')}
                        className={styles.formInput}
                        rules={[{ required: true, message: tr('REQUIRED') }]}
                    >
                        <Input
                            size="large"
                            placeholder={td('VEHICLE_YEAR')}
                        />
                    </Form.Item>
                </Space>
            </Col>
            <Col xs={24} md={24} lg={12}>
                <Space direction="vertical" size={24}>
                    <Form.Item
                        name="MCNumber"
                        label={td('MC_NUMBER')}
                        className={styles.formInput}
                        rules={[{ required: true, message: tr('REQUIRED') }]}
                    >
                        <Input
                            size="large"
                            placeholder={td('MC_NUMBER')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="vehicleColor"
                        label={td('VEHICLE_COLOR')}
                        className={styles.formInput}
                        rules={[{ required: true, message: tr('REQUIRED') }]}
                    >
                        <Input
                            size="large"
                            placeholder={td('VEHICLE_COLOR')}
                        />
                    </Form.Item>
                </Space>
            </Col>
        </Row>
    );

    const header = (info: any) => (
        <div className={stylesCard.header}>
            <Avatar
                className={stylesCard.avatar}
                size={56}
                src={info.icon}
            />
            <div className={stylesCard.title}>
                <span className="cl--primary-white medium paragraph--3">{info.title }</span>
            </div>
        </div>
    );

    return (
        <div className="fullWidth fullHeight home flex-row center-row-start">
            <div className="contentLayouts fullWidth fullHeight bg--primary-white">
                <Space direction="vertical" size={64} className="fullWidth">
                    <HeaderSection
                        title={`${driver?.name || ''} ${driver?.lastName || ''} - ${ts('UPDATE')}`}
                        icon={
                            <Avatar size={40} className="iconHeader" shape="square" src={IMAGES.icons.driver} />
                        }
                        showBack
                        defaultBack="/drivers"
                    />
                    {
                        loading
                            ? <Spin className="center fullWidth" />
                            : (
                                <div style={{ padding: '0 32px 64px 32px' }}>
                                    <Space direction="vertical" size={24} className="fullWidth">
                                        <Form
                                            form={form}
                                            onFinish={onSubmit}
                                            layout="vertical"
                                            className={styles.form}
                                        >
                                            <Space direction="vertical" size={24}>
                                                {error && (
                                                    <Alert message={error} type="error" />
                                                )}
                                                <Space direction="vertical" size={64}>
                                                    <div className={stylesCard.driverCard}>
                                                        { header(headers[0]) }
                                                        { renderDriverForm() }
                                                    </div>
                                                    <div className={stylesCard.driverCard}>
                                                        { header(headers[1]) }
                                                        { renderVehicleForm() }
                                                    </div>
                                                </Space>
                                            </Space>
                                        </Form>
                                        <Row
                                            justify="space-around"
                                            align="middle"
                                            className="fullWidth fullHeight noMargin"
                                            gutter={[24, 24]}
                                        >
                                            <Col xs={24} lg={12} style={{ paddingLeft: 0 }}>
                                                <Button
                                                    type="ghost"
                                                    className={cs(styles.button, 'lg')}
                                                    onClick={() => navigate(`/drivers/view/${params.id}`)}
                                                    loading={loading}
                                                >
                                                    {ts('CANCEL')}
                                                </Button>
                                            </Col>
                                            <Col xs={24} lg={12} style={{ paddingRight: 0 }}>
                                                <Button
                                                    type="primary"
                                                    className={cs(styles.button, 'lg')}
                                                    onClick={() => form.submit()}
                                                    loading={loading}
                                                >
                                                    {ts('UPDATE')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Space>
                                </div>
                            )
                    }
                </Space>
            </div>
        </div>
    );
};

export default UpdateDriver;
