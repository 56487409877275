import cs from 'classnames';
import IMAGES from '../../const/images';
import styles from '../../styles/screens/Onboarding.module.scss';
import SwitchLanguage from '../SwitchLanguage';

const Header = () => (
    <div className={cs('flex-row center-row-center', styles.containerHeader)}>
        <img alt="picker" className={styles.logo} src={IMAGES.logo.horizontal} />
        <SwitchLanguage />
    </div>
);

export default Header;
