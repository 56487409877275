import { useState } from 'react';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { getBookings, getMiniBookings } from '../api/workspace';

type Paginator = {
    currentPage: number;
    pages: number;
    pageSize: number;
    sort?: string;
    field?: string;
};

type QueryParams = {
    limit?: number;
    skip?: number;
    search?: string | null;
    typeBookings?: string;
    numericId?: string | null;
    from?: string | null;
    to?: string | null;
    sort?: string;
    field?: string;
    smallFleetID?: string;
};

interface Props {
    typeBookings: string | any;
    search?: string | null;
    numericId?: string | null;
    args: any;
    startDate?: string | null;
    endDate?: string | null;
}

const useBookings = ({
    typeBookings = 'onlyActives',
    search,
    numericId,
    startDate,
    endDate,
    args = {},
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [paginator, setPaginator] = useState<Paginator>({
        currentPage: 1,
        pages: 0,
        pageSize: args.limit,
    });
    const [data, setData] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const active = useSelector((state: any) => state.workspace.active);

    const fetchBookings = async () => {
        setLoading(true);

        const query: QueryParams = {
            limit: paginator.pageSize,
            skip: (paginator.currentPage - 1) * paginator.pageSize,
            [typeBookings]: true,
            search,
            numericId,
            from: startDate,
            to: endDate,
            sort: paginator.sort,
            field: paginator.field,
            smallFleetID: active._id,
        };

        if (!search || search === '') delete query.search;
        if (!numericId || numericId === '') delete query.numericId;
        if (!startDate || startDate === '') delete query.from;
        if (!endDate || endDate === '') delete query.to;
        if (!paginator.sort) delete query.sort;
        if (!paginator.field) delete query.field;

        if (typeBookings === 'onlyPast') {
            getMiniBookings({ ...query })
                .then(({ body }: any) => {
                    setData(body.bookings);
                    setTotal(body.total);
                })
                .catch((err: any) => {
                    const message = err?.response?.data?.message || err.message;
                    notification.error({
                        message: 'Error',
                        description: message,
                    });
                })
                .finally(() => setLoading(false));
        } else {
            getBookings({ ...query })
                .then(({ body }: any) => {
                    setData(body.bookings);
                    setTotal(body.total);
                })
                .catch((err: any) => {
                    const message = err?.response?.data?.message || err.message;
                    notification.error({
                        message: 'Error',
                        description: message,
                    });
                })
                .finally(() => setLoading(false));
        }
    };

    const refreshData = (value?: string, isNumericId?: boolean) => {
        if (isNumericId) numericId = value;
        else search = value;
        fetchBookings();
    };

    const refreshDate = (value: string[]) => {
        const [newStartDate, newEndDate] = value;
        startDate = newStartDate;
        endDate = newEndDate;
        fetchBookings();
    };

    const onPageChange = (page: number, size: any) => setPaginator({ ...paginator, pageSize: size, currentPage: page });

    const onTableChange = (paginator: any, filter: any, sorter: any) => {
        const field = sorter.order ? sorter.field : undefined;
        setPaginator({
            ...paginator,
            currentPage: paginator.current,
            pageSize: paginator.pageSize,
            sort: sorter.order,
            field,
        });
    };

    const onPageSizeChange = (current: number, size: number) => {
        setPaginator({ ...paginator, pageSize: size, currentPage: current });
    };

    return [
        loading,
        data,
        total,
        refreshData,
        fetchBookings,
        paginator,
        {
            onPageChange,
            onPageSizeChange,
            onTableChange,
        },
        refreshDate,
    ];
};

export default useBookings;
