import { Space } from 'antd';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { BiPackage } from 'react-icons/bi';
import useBookings from '../hooks/useBookings';
import { Filters } from '../components/Bookings';
import TableBookings, { TableBookingsProps } from '../components/TableBookings/TableBookings';
import HeaderSection from '../components/HeaderSection';

const ActiveBookings = () => {
    const { t } = useTranslation('bookings', { keyPrefix: 'BOOKINGS' });

    const active = useSelector((state: any) => state.workspace.active);

    const LIMIT = 10;

    const [search, setSearch] = useState<string>('');
    const [startDate] = useState<string>('');
    const [endDate] = useState<string>('');
    const [numericId, setNumericId] = useState<string>('');

    const [loading, bookings, total, refreshData, fetchBookings, paginator, props]: any = useBookings({
        typeBookings: 'onlyActives',
        search,
        args: { limit: LIMIT },
        startDate,
        endDate,
    });

    const fetchDebounced = useCallback<any>(debounce(refreshData, 1000), []);

    const handleSearch = (e: any) => {
        const { value } = e.target;
        setSearch(value);
        fetchDebounced(value, false);
    };

    const handleNumericId = (e: any) => {
        const { value } = e.target;
        setNumericId(value);
        fetchDebounced(value, true);
    };

    useEffect(() => {
        if (!active) return;
        fetchBookings();
    }, [active, paginator]);

    return (
        <div className="contentLayouts fullWidth fullHeight bg--primary-white">
            <Space direction="vertical" size={64} className="fullWidth">
                <Space direction="vertical" size={24} style={{ width: '100%' }}>
                    <HeaderSection
                        title={t('ACTIVE_BOOKINGS')}
                        icon={<BiPackage size={40} className="iconHeader" />}
                    />
                    <Filters
                        loading={loading}
                        search={search}
                        handleSearch={handleSearch}
                        numericId={numericId}
                        handleNumericId={handleNumericId}
                        refreshData={refreshData}
                    />
                    <TableBookings
                        loading={loading}
                        total={total}
                        data={bookings}
                        paginator={paginator}
                        refreshData={refreshData}
                        showSort
                        {...props as Partial<TableBookingsProps>}
                    />
                </Space>
            </Space>
        </div>
    );
};

export default ActiveBookings;
