import {
    Space,
} from 'antd';

import DriversTable from '@/components/Drivers/DriversTable';

const Drivers = () => (
    <div className="fullHeight fullWidth home flex-row center-row-start">
        <div className="contentLayouts fullWidth fullHeight bg--primary-white">
            <Space direction="vertical" size={64} className="fullWidth">
                <DriversTable showActions />
            </Space>
        </div>
    </div>
);

export default Drivers;
