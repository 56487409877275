/* eslint-disable no-unused-vars */
import { PaymentMethod, Currency } from './payment';

export interface Coordinates {
  coordinates: number[];
  type: string;
}

export interface DistanceBreakUp {
  extraDistance: number;
  baseDistance: number;
  billedDistance: number;
  realDistance: number;
}

export interface PriceBreakUp {
  orderAmount: number;
  businessDeliveryFee: number;
  baseFare: number;
  perKmCharge: number;
}

export interface RegionID {
  _id: string;
  regionStatus: string;
}

export enum BookingStatus {
  PENDING = 'PENDING',
  ON_DECK = 'ON_DECK',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  ARRIVED_AT_PICKUP = 'ARRIVED_AT_PICKUP',
  WAY_TO_DELIVER = 'WAY_TO_DELIVER',
  ARRIVED_AT_DELIVERY = 'ARRIVED_AT_DELIVERY',
  ACCEPTED = 'ACCEPTED',
  ENROUTE_TO_PICKUP = 'ENROUTE_TO_PICKUP',
  PICKED_UP = 'PICKED_UP',
  ENROUTE_TO_DELIVERY = 'ENROUTE_TO_DELIVERY',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  CANCELLED_BY_DRIVER = 'CANCELLED_BY_DRIVER',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  CANCELLED_BY_SERVICE_PROVIDER = 'CANCELLED_BY_SERVICE_PROVIDER',
  CANCELLED_BY_ADMIN = 'CANCELLED_BY_ADMIN',
  EXPIRED = 'EXPIRED',
  ON_HOLD = 'ON_HOLD',
  REJECTED_BY_DRIVER = 'REJECTED_BY_DRIVER',
  REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
  REJECTED_BY_BUSINESS = 'REJECTED_BY_BUSINESS',
  CANCELLED_REQUEST = 'CANCELLED_REQUEST',
  CANCELLED_BY_BUSINESS = 'CANCELLED_BY_BUSINESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  TIME_PASSED = 'TIME_PASSED',
  NOT_DELIVERED = 'NOT_DELIVERED'
}

export interface StatusUpdate {
  newStatus: number;
  newStatusText: BookingStatus;
  previousStatus: number;
  timeStamp: Date;
}

export interface Booking {
  _id: string;
  updatedAt: Date;
  createdAt: Date;
  bookingNumericId: number;
  customerName: string;
  paymentMethod: PaymentMethod;
  businessName: string;
  statusText: BookingStatus;
  regionID: RegionID;
  distanceBreakUp: DistanceBreakUp;
  pickupCoordinates: Coordinates;
  pickupReferences: null | string;
  pickupAddress: string;
  deliveryCoordinates: Coordinates;
  deliveryAddress: string;
  deliveryReferences: string;
  driverMobile: null | string;
  driverName: null | string;
  driverImage: null | string;
  deliveryProvider: string;
  bookingCompletionDateTime?: Date;
  statusUpdates: StatusUpdate[];
  priceBreakUp: PriceBreakUp;
  sumOrderAndDelivery: boolean;
  ServiceAmount: number;
  deliveryFee: number;
  currency: Currency;
}
