import {
    Button, Space, Form, Input, Alert,
} from 'antd';
import cs from 'classnames';

import { BsInfoCircle } from 'react-icons/bs';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import IMAGES from '../const/images';

import styles from '../styles/screens/Onboarding.module.scss';
import { startValidateEmail, startLoginEmailPassword } from '../actions/auth';
import { Header, Options, Footer } from '../components/Onboarding';

const Login = () => {
    const { t } = useTranslation('login');
    const { auth: { emailExist }, ui: { loading, msgError } } = useSelector((state: any) => state);

    const dispatch = useDispatch<any>();

    const resetPassword = () => (
        <Link to="/reset-password" key="reset">
            {t('RESET_IT_HERE')}
        </Link>
    );

    const createAccountLink = () => (
        <Link to="/signup" className={styles.createAccountLink}>
            {t('CREATE_AN_ACCOUNT')}
        </Link>
    );

    const handleSubmit = async ({ email, password } : { email: string, password: string}) => {
        if (emailExist) {
            dispatch(startLoginEmailPassword(email, password));
            return;
        }
        dispatch(startValidateEmail(email));
    };
    const containerForm = () => (
        <div className={styles.containerForm}>
            <Form onFinish={handleSubmit} layout="vertical" className={styles.form}>
                <Space direction="vertical" size={24}>
                    {msgError && (
                        <Alert message={msgError} type="error" />
                    )}
                    <Form.Item
                        name="email"
                        label={t('EMAIL')}
                        className={styles.formInput}
                        rules={[{ type: 'email', message: t('INVALID_EMAIL') }, { required: true }]}
                    >
                        <Input
                            size="large"
                            placeholder={t('EMAIL')}
                            disabled={emailExist}
                        />
                    </Form.Item>
                    {emailExist !== null && !emailExist && (
                        <Alert
                            message={(
                                <div>
                                    {t('USER_NOT_FOUND')}
                                    {createAccountLink()}
                                </div>
                            )}
                            type="info"
                        />
                    )}
                    {emailExist && (
                        <Form.Item
                            name="password"
                            label={t('PASSWORD')}
                            className={styles.formInput}
                            rules={[{ required: true }]}
                        >
                            <Input.Password
                                size="large"
                                type="password"
                                placeholder={t('PASSWORD')}
                            />
                        </Form.Item>
                    )}
                </Space>

                <Button
                    type="primary"
                    htmlType="submit"
                    className={cs(styles.button, 'fullWidth')}
                    loading={loading}
                >
                    {emailExist ? t('START_WITH_PICKER') : t('CONTINUE')}
                </Button>

                <div className={cs(styles.forgotPassword, 'flex-row center-row-center')}>
                    <BsInfoCircle />
                    { t('FORGOT_TEXT') }
                    { resetPassword() }
                </div>

            </Form>
        </div>
    );

    return (
        <div
            style={{ backgroundImage: `url(${IMAGES.login.spirals})` }}
            className={cs(styles.pageLayout, 'flex-column center-column-center')}
        >
            <div className={styles.left}>
                <div className={styles.containerLeft}>
                    <Space direction="vertical" size={40}>
                        <Header />
                        <span className="cl--secondary-purple title--header-1 center">
                            { t('LOGIN_TITLE') }
                        </span>
                        <Options active={0} />
                        <Space direction="vertical" size={72}>
                            { containerForm() }
                            <Footer />
                        </Space>
                    </Space>
                </div>
            </div>

            <div className={cs(styles.right, 'flex-column center-column-center')}>
                <div className={styles.containerImages}>
                    <span />
                </div>
            </div>
        </div>
    );
};

export default Login;
