import { Col, Form, message, Row, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { BiCloudUpload } from "react-icons/bi";

import stylesOnb from '@/styles/screens/Onboarding.module.scss';
import ImagePreviewSection from "./components/ImageSection";
import { useState } from "react";

const UploadLogo = ({
    setCurrentSrc,
    currentSrc
}: any) => {

    const { t: cw } = useTranslation('onboarding', { keyPrefix: 'CREATE_WORKSPACE' });

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const draggerProps = {
        name: 'file',
        multiple: false,
        beforeUpload: (file: any) => {
            switch (file.type) {
                case "image/jpeg":
                case "image/png": {
                    setCurrentSrc(URL.createObjectURL(file));
                    return file;
                }
                default: {
                    message.error(cw('FILE_UPLOADED_NOT_ACCEPTED'));
                    return Upload.LIST_IGNORE
                }
            }
        },
        onRemove: (e: any) => {
            setCurrentSrc(null);
        }
    };

    return (
        <Row justify="space-between" align="middle">
            <Col lg={11} md={24}>
                <Form.Item
                    name="logo"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    className={stylesOnb.dragger}
                >
                    <Upload.Dragger style={{padding: "16px"}} itemRender={() => <></>} maxCount={1} {...draggerProps}>
                        <BiCloudUpload color="#3883FF" size={48} />
                        <span className="medium centdelivery-partner/createSmallFleeter paragraph--3 cl--secondary-purple">
                            {cw('HELP_LOGO')}
                            <br />
                            {cw('HELP_LOGO_DESCRIPTION')}
                        </span>
                    </Upload.Dragger>
                </Form.Item>
            </Col>
            <Col lg={11} md={24} span={11}>
                <ImagePreviewSection src={currentSrc} />
            </Col>
        </Row>
    )
}

export default UploadLogo;